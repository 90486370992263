/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

/* eslint-disable max-len */
import { IconName } from './IconName';
import { IconCategory } from './IconCategory';
import { PointIcon } from './PointIcon';

export const AllPointIcons: PointIcon[] = [
  { name: IconName.Icon0CircleFill, category: IconCategory.Geometries },
  { name: IconName.Icon0CircleHalf, category: IconCategory.Geometries },
  { name: IconName.Icon0CircleSquare, category: IconCategory.Geometries },
  { name: IconName.Icon0Circle, category: IconCategory.Geometries },
  { name: IconName.Icon0SquareFill, category: IconCategory.Geometries },
  { name: IconName.Icon0SquareHalf, category: IconCategory.Geometries },
  { name: IconName.Icon0Square, category: IconCategory.Geometries },
  { name: IconName.Icon0StarFill, category: IconCategory.Geometries },
  { name: IconName.Icon0StarHalf, category: IconCategory.Geometries },
  { name: IconName.Icon0Star, category: IconCategory.Geometries },
  { name: IconName.Icon0Stars, category: IconCategory.Geometries },
  { name: IconName.Icon0TriangleFill, category: IconCategory.Geometries },
  { name: IconName.Icon0TriangleHalf, category: IconCategory.Geometries },
  { name: IconName.Icon0Triangle, category: IconCategory.Geometries },
  { name: IconName.IconArrow90DegDown, category: IconCategory.Arrows },
  { name: IconName.IconArrow90DegLeft, category: IconCategory.Arrows },
  { name: IconName.IconArrow90DegRight, category: IconCategory.Arrows },
  { name: IconName.IconArrow90DegUp, category: IconCategory.Arrows },
  { name: IconName.IconArrowClockwise, category: IconCategory.Arrows },
  { name: IconName.IconArrowCounterclockwise, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownCircleFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownCircle, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownLeftCircleFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownLeftCircle, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownLeftSquareFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownLeftSquare, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownLeft, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownRightCircleFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownRightCircle, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownRightSquareFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownRightSquare, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownRight, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownShort, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownSquareFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownSquare, category: IconCategory.Arrows },
  { name: IconName.IconArrowDownUp, category: IconCategory.Arrows },
  { name: IconName.IconArrowDown, category: IconCategory.Arrows },
  { name: IconName.IconArrowLeftCircleFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowLeftCircle, category: IconCategory.Arrows },
  { name: IconName.IconArrowLeftRight, category: IconCategory.Arrows },
  { name: IconName.IconArrowLeftShort, category: IconCategory.Arrows },
  { name: IconName.IconArrowLeftSquareFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowLeftSquare, category: IconCategory.Arrows },
  { name: IconName.IconArrowLeft, category: IconCategory.Arrows },
  { name: IconName.IconArrowRepeat, category: IconCategory.Arrows },
  { name: IconName.IconArrowReturnLeft, category: IconCategory.Arrows },
  { name: IconName.IconArrowReturnRight, category: IconCategory.Arrows },
  { name: IconName.IconArrowRightCircleFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowRightCircle, category: IconCategory.Arrows },
  { name: IconName.IconArrowRightShort, category: IconCategory.Arrows },
  { name: IconName.IconArrowRightSquareFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowRightSquare, category: IconCategory.Arrows },
  { name: IconName.IconArrowRight, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpCircleFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpCircle, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpLeftCircleFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpLeftCircle, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpLeftSquareFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpLeftSquare, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpLeft, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpRightCircleFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpRightCircle, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpRightSquareFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpRightSquare, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpRight, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpShort, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpSquareFill, category: IconCategory.Arrows },
  { name: IconName.IconArrowUpSquare, category: IconCategory.Arrows },
  { name: IconName.IconArrowUp, category: IconCategory.Arrows },
  { name: IconName.IconArrowsAngleContract, category: IconCategory.Arrows },
  { name: IconName.IconArrowsFullscreen, category: IconCategory.Arrows },
  { name: IconName.IconArrowsMove, category: IconCategory.Arrows },
  { name: IconName.IconBagCheckFill, category: IconCategory.Objects },
  { name: IconName.IconBagCheck, category: IconCategory.Objects },
  { name: IconName.IconBagDashFill, category: IconCategory.Objects },
  { name: IconName.IconBagDash, category: IconCategory.Objects },
  { name: IconName.IconBagFill, category: IconCategory.Objects },
  { name: IconName.IconBagPlusFill, category: IconCategory.Objects },
  { name: IconName.IconBagPlus, category: IconCategory.Objects },
  { name: IconName.IconBagXFill, category: IconCategory.Objects },
  { name: IconName.IconBagX, category: IconCategory.Objects },
  { name: IconName.IconBag, category: IconCategory.Objects },
  { name: IconName.IconBank, category: IconCategory.Objects },
  { name: IconName.IconBank2, category: IconCategory.Objects },
  { name: IconName.IconBasketFill, category: IconCategory.Objects },
  { name: IconName.IconBasket, category: IconCategory.Objects },
  { name: IconName.IconBasket2Fill, category: IconCategory.Objects },
  { name: IconName.IconBasket2, category: IconCategory.Objects },
  { name: IconName.IconBasket3Fill, category: IconCategory.Objects },
  { name: IconName.IconBasket3, category: IconCategory.Objects },
  { name: IconName.IconBicycle, category: IconCategory.Objects },
  { name: IconName.IconBinocularsFill, category: IconCategory.Objects },
  { name: IconName.IconBinoculars, category: IconCategory.Objects },
  { name: IconName.IconBookFill, category: IconCategory.Objects },
  { name: IconName.IconBookHalf, category: IconCategory.Objects },
  { name: IconName.IconBoxArrowDownLeft, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowDownRight, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowInDownLeft, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowInDownRight, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowInDown, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowInLeft, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowInRight, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowInUpLeft, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowInUpRight, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowInUp, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowLeft, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowRight, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowUpLeft, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowUpRight, category: IconCategory.Arrows },
  { name: IconName.IconBoxArrowUp, category: IconCategory.Arrows },
  { name: IconName.IconBoxSeam, category: IconCategory.Objects },
  { name: IconName.IconBox, category: IconCategory.Objects },
  { name: IconName.IconBrightnessAltHighFill, category: IconCategory.Weather },
  { name: IconName.IconBrightnessAltHigh, category: IconCategory.Weather },
  { name: IconName.IconBrightnessAltLowFill, category: IconCategory.Weather },
  { name: IconName.IconBrightnessAltLow, category: IconCategory.Weather },
  { name: IconName.IconBrightnessHighFill, category: IconCategory.Weather },
  { name: IconName.IconBrightnessHigh, category: IconCategory.Weather },
  { name: IconName.IconBrightnessLowFill, category: IconCategory.Weather },
  { name: IconName.IconBrightnessLow, category: IconCategory.Weather },
  { name: IconName.IconBroadcastPin, category: IconCategory.Objects },
  { name: IconName.IconBroadcast, category: IconCategory.Objects },
  { name: IconName.IconBucketFill, category: IconCategory.Objects },
  { name: IconName.IconBucket, category: IconCategory.Objects },
  { name: IconName.IconBugFill, category: IconCategory.Objects },
  { name: IconName.IconBug, category: IconCategory.Objects },
  { name: IconName.IconCameraFill, category: IconCategory.Objects },
  { name: IconName.IconCameraReelsFill, category: IconCategory.Objects },
  { name: IconName.IconCameraReels, category: IconCategory.Objects },
  { name: IconName.IconCameraVideoFill, category: IconCategory.Objects },
  { name: IconName.IconCameraVideoOffFill, category: IconCategory.Objects },
  { name: IconName.IconCameraVideoOff, category: IconCategory.Objects },
  { name: IconName.IconCameraVideo, category: IconCategory.Objects },
  { name: IconName.IconCamera, category: IconCategory.Objects },
  { name: IconName.IconCamera2, category: IconCategory.Objects },
  { name: IconName.IconCartCheckFill, category: IconCategory.Objects },
  { name: IconName.IconCartCheck, category: IconCategory.Objects },
  { name: IconName.IconCartDashFill, category: IconCategory.Objects },
  { name: IconName.IconCartDash, category: IconCategory.Objects },
  { name: IconName.IconCartFill, category: IconCategory.Objects },
  { name: IconName.IconCartPlusFill, category: IconCategory.Objects },
  { name: IconName.IconCartPlus, category: IconCategory.Objects },
  { name: IconName.IconCartXFill, category: IconCategory.Objects },
  { name: IconName.IconCartX, category: IconCategory.Objects },
  { name: IconName.IconCart, category: IconCategory.Objects },
  { name: IconName.IconCart2, category: IconCategory.Objects },
  { name: IconName.IconCart3, category: IconCategory.Objects },
  { name: IconName.IconCart4, category: IconCategory.Objects },
  { name: IconName.IconCashCoin, category: IconCategory.Objects },
  { name: IconName.IconCashStack, category: IconCategory.Objects },
  { name: IconName.IconCash, category: IconCategory.Objects },
  { name: IconName.IconCloudArrowDownFill, category: IconCategory.Weather },
  { name: IconName.IconCloudArrowDown, category: IconCategory.Weather },
  { name: IconName.IconCloudArrowUpFill, category: IconCategory.Weather },
  { name: IconName.IconCloudArrowUp, category: IconCategory.Weather },
  { name: IconName.IconCloudCheckFill, category: IconCategory.Weather },
  { name: IconName.IconCloudCheck, category: IconCategory.Weather },
  { name: IconName.IconCloudDrizzleFill, category: IconCategory.Weather },
  { name: IconName.IconCloudDrizzle, category: IconCategory.Weather },
  { name: IconName.IconCloudFill, category: IconCategory.Weather },
  { name: IconName.IconCloudFogFill, category: IconCategory.Weather },
  { name: IconName.IconCloudFog, category: IconCategory.Weather },
  { name: IconName.IconCloudFog2Fill, category: IconCategory.Weather },
  { name: IconName.IconCloudFog2, category: IconCategory.Weather },
  { name: IconName.IconCloudHailFill, category: IconCategory.Weather },
  { name: IconName.IconCloudHail, category: IconCategory.Weather },
  { name: IconName.IconCloudHaze1, category: IconCategory.Weather },
  { name: IconName.IconCloudHazeFill, category: IconCategory.Weather },
  { name: IconName.IconCloudHaze, category: IconCategory.Weather },
  { name: IconName.IconCloudHaze2Fill, category: IconCategory.Weather },
  { name: IconName.IconCloudLightningFill, category: IconCategory.Weather },
  { name: IconName.IconCloudLightningRainFill, category: IconCategory.Weather },
  { name: IconName.IconCloudLightningRain, category: IconCategory.Weather },
  { name: IconName.IconCloudLightning, category: IconCategory.Weather },
  { name: IconName.IconCloudMinusFill, category: IconCategory.Weather },
  { name: IconName.IconCloudMinus, category: IconCategory.Weather },
  { name: IconName.IconCloudMoonFill, category: IconCategory.Weather },
  { name: IconName.IconCloudMoon, category: IconCategory.Weather },
  { name: IconName.IconCloudPlusFill, category: IconCategory.Weather },
  { name: IconName.IconCloudPlus, category: IconCategory.Weather },
  { name: IconName.IconCloudRainFill, category: IconCategory.Weather },
  { name: IconName.IconCloudRainHeavyFill, category: IconCategory.Weather },
  { name: IconName.IconCloudRainHeavy, category: IconCategory.Weather },
  { name: IconName.IconCloudRain, category: IconCategory.Weather },
  { name: IconName.IconCloudSlashFill, category: IconCategory.Weather },
  { name: IconName.IconCloudSlash, category: IconCategory.Weather },
  { name: IconName.IconCloudSleetFill, category: IconCategory.Weather },
  { name: IconName.IconCloudSleet, category: IconCategory.Weather },
  { name: IconName.IconCloudSnowFill, category: IconCategory.Weather },
  { name: IconName.IconCloudSnow, category: IconCategory.Weather },
  { name: IconName.IconCloudSunFill, category: IconCategory.Weather },
  { name: IconName.IconCloudSun, category: IconCategory.Weather },
  { name: IconName.IconCompassFill, category: IconCategory.Objects },
  { name: IconName.IconCompass, category: IconCategory.Objects },
  { name: IconName.IconCreditCard2BackFill, category: IconCategory.Objects },
  { name: IconName.IconCreditCard2Back, category: IconCategory.Objects },
  { name: IconName.IconCreditCard2FrontFill, category: IconCategory.Objects },
  { name: IconName.IconCreditCard2Front, category: IconCategory.Objects },
  { name: IconName.IconCreditCardFill, category: IconCategory.Objects },
  { name: IconName.IconCreditCard, category: IconCategory.Objects },
  { name: IconName.IconCupFill, category: IconCategory.Objects },
  { name: IconName.IconCupStraw, category: IconCategory.Objects },
  { name: IconName.IconCup, category: IconCategory.Objects },
  { name: IconName.IconCurrencyBitcoin, category: IconCategory.Objects },
  { name: IconName.IconCurrencyDollar, category: IconCategory.Objects },
  { name: IconName.IconCurrencyEuro, category: IconCategory.Objects },
  { name: IconName.IconCurrencyExchange, category: IconCategory.Objects },
  { name: IconName.IconCurrencyPound, category: IconCategory.Objects },
  { name: IconName.IconCurrencyYen, category: IconCategory.Objects },
  { name: IconName.IconDiamondFill, category: IconCategory.Objects },
  { name: IconName.IconDiamondHalf, category: IconCategory.Objects },
  { name: IconName.IconDiamond, category: IconCategory.Objects },
  { name: IconName.IconDice1Fill, category: IconCategory.Objects },
  { name: IconName.IconDice1, category: IconCategory.Objects },
  { name: IconName.IconDice2Fill, category: IconCategory.Objects },
  { name: IconName.IconDice2, category: IconCategory.Objects },
  { name: IconName.IconDice3Fill, category: IconCategory.Objects },
  { name: IconName.IconDice3, category: IconCategory.Objects },
  { name: IconName.IconDice4Fill, category: IconCategory.Objects },
  { name: IconName.IconDice4, category: IconCategory.Objects },
  { name: IconName.IconDice5Fill, category: IconCategory.Objects },
  { name: IconName.IconDice5, category: IconCategory.Objects },
  { name: IconName.IconDice6Fill, category: IconCategory.Objects },
  { name: IconName.IconDice6, category: IconCategory.Objects },
  { name: IconName.IconDiscFill, category: IconCategory.Objects },
  { name: IconName.IconDisc, category: IconCategory.Objects },
  { name: IconName.IconDoorClosedFill, category: IconCategory.Objects },
  { name: IconName.IconDoorClosed, category: IconCategory.Objects },
  { name: IconName.IconDoorOpenFill, category: IconCategory.Objects },
  { name: IconName.IconDoorOpen, category: IconCategory.Objects },
  { name: IconName.IconDropletFill, category: IconCategory.Objects },
  { name: IconName.IconDropletHalf, category: IconCategory.Objects },
  { name: IconName.IconEggFill, category: IconCategory.Objects },
  { name: IconName.IconEggFried, category: IconCategory.Objects },
  { name: IconName.IconEgg, category: IconCategory.Objects },
  { name: IconName.IconEmojiAngryFill, category: IconCategory.Emojis },
  { name: IconName.IconEmojiAngry, category: IconCategory.Emojis },
  { name: IconName.IconEmojiDizzyFill, category: IconCategory.Emojis },
  { name: IconName.IconEmojiDizzy, category: IconCategory.Emojis },
  { name: IconName.IconEmojiExpressionlessFill, category: IconCategory.Emojis },
  { name: IconName.IconEmojiExpressionless, category: IconCategory.Emojis },
  { name: IconName.IconEmojiFrownFill, category: IconCategory.Emojis },
  { name: IconName.IconEmojiFrown, category: IconCategory.Emojis },
  { name: IconName.IconEmojiHeartEyesFill, category: IconCategory.Emojis },
  { name: IconName.IconEmojiHeartEyes, category: IconCategory.Emojis },
  { name: IconName.IconEmojiLaughingFill, category: IconCategory.Emojis },
  { name: IconName.IconEmojiLaughing, category: IconCategory.Emojis },
  { name: IconName.IconEmojiNeutralFill, category: IconCategory.Emojis },
  { name: IconName.IconEmojiNeutral, category: IconCategory.Emojis },
  { name: IconName.IconEmojiSmileFill, category: IconCategory.Emojis },
  { name: IconName.IconEmojiSmileUpsideDownFill, category: IconCategory.Emojis },
  { name: IconName.IconEmojiSmileUpsideDown, category: IconCategory.Emojis },
  { name: IconName.IconEmojiSmile, category: IconCategory.Emojis },
  { name: IconName.IconEmojiSunglassesFill, category: IconCategory.Emojis },
  { name: IconName.IconEmojiSunglasses, category: IconCategory.Emojis },
  { name: IconName.IconEmojiWinkFill, category: IconCategory.Emojis },
  { name: IconName.IconEmojiWink, category: IconCategory.Emojis },
  { name: IconName.IconEnvelopeFill, category: IconCategory.Objects },
  { name: IconName.IconEnvelopeOpenFill, category: IconCategory.Objects },
  { name: IconName.IconEnvelopeOpen, category: IconCategory.Objects },
  { name: IconName.IconEnvelope, category: IconCategory.Objects },
  { name: IconName.IconExclamationCircleFill, category: IconCategory.Symbols },
  { name: IconName.IconExclamationCircle, category: IconCategory.Symbols },
  { name: IconName.IconExclamationDiamondFill, category: IconCategory.Symbols },
  { name: IconName.IconExclamationDiamond, category: IconCategory.Symbols },
  { name: IconName.IconExclamationLg, category: IconCategory.Symbols },
  { name: IconName.IconExclamationOctagonFill, category: IconCategory.Symbols },
  { name: IconName.IconExclamationOctagon, category: IconCategory.Symbols },
  { name: IconName.IconExclamationSquareFill, category: IconCategory.Symbols },
  { name: IconName.IconExclamationSquare, category: IconCategory.Symbols },
  { name: IconName.IconExclamationTriangleFill, category: IconCategory.Symbols },
  { name: IconName.IconExclamationTriangle, category: IconCategory.Symbols },
  { name: IconName.IconExclamation, category: IconCategory.Symbols },
  { name: IconName.IconExclude, category: IconCategory.Symbols },
  { name: IconName.IconEyeFill, category: IconCategory.Objects },
  { name: IconName.IconEyeSlashFill, category: IconCategory.Objects },
  { name: IconName.IconEyeSlash, category: IconCategory.Objects },
  { name: IconName.IconEye, category: IconCategory.Objects },
  { name: IconName.IconEyedropper, category: IconCategory.Objects },
  { name: IconName.IconEyeglasses, category: IconCategory.Objects },
  { name: IconName.IconFlower1, category: IconCategory.Objects },
  { name: IconName.IconFlower2, category: IconCategory.Objects },
  { name: IconName.IconFlower3, category: IconCategory.Objects },
  { name: IconName.IconFunnelFill, category: IconCategory.Objects },
  { name: IconName.IconFunnel, category: IconCategory.Objects },
  { name: IconName.IconGearFill, category: IconCategory.Objects },
  { name: IconName.IconGearWideConnected, category: IconCategory.Objects },
  { name: IconName.IconGearWide, category: IconCategory.Objects },
  { name: IconName.IconGear, category: IconCategory.Objects },
  { name: IconName.IconGem, category: IconCategory.Objects },
  { name: IconName.IconGeoAltFill, category: IconCategory.Pins },
  { name: IconName.IconGeoAlt, category: IconCategory.Pins },
  { name: IconName.IconGeoFill, category: IconCategory.Pins },
  { name: IconName.IconGeo, category: IconCategory.Pins },
  { name: IconName.IconHammer, category: IconCategory.Objects },
  { name: IconName.IconHandbagFill, category: IconCategory.Objects },
  { name: IconName.IconHandbag, category: IconCategory.Objects },
  { name: IconName.IconHeartFill, category: IconCategory.Objects },
  { name: IconName.IconHeartHalf, category: IconCategory.Objects },
  { name: IconName.IconHeart, category: IconCategory.Objects },
  { name: IconName.IconHeptagonFill, category: IconCategory.Geometries },
  { name: IconName.IconHeptagonHalf, category: IconCategory.Geometries },
  { name: IconName.IconHeptagon, category: IconCategory.Geometries },
  { name: IconName.IconHouseDoorFill, category: IconCategory.Objects },
  { name: IconName.IconHouseDoor, category: IconCategory.Objects },
  { name: IconName.IconHouseFill, category: IconCategory.Objects },
  { name: IconName.IconHouse, category: IconCategory.Objects },
  { name: IconName.IconHurricane, category: IconCategory.Weather },
  { name: IconName.IconInfoCircleFill, category: IconCategory.Symbols },
  { name: IconName.IconInfoCircle, category: IconCategory.Symbols },
  { name: IconName.IconInfoLg, category: IconCategory.Symbols },
  { name: IconName.IconInfoSquareFill, category: IconCategory.Symbols },
  { name: IconName.IconInfoSquare, category: IconCategory.Symbols },
  { name: IconName.IconInfo, category: IconCategory.Symbols },
  { name: IconName.IconJournalAlbum, category: IconCategory.Objects },
  { name: IconName.IconJournalCheck, category: IconCategory.Objects },
  { name: IconName.IconLadder, category: IconCategory.Objects },
  { name: IconName.IconLifePreserver, category: IconCategory.Objects },
  { name: IconName.IconLightbulbFill, category: IconCategory.Objects },
  { name: IconName.IconLightbulbOffFill, category: IconCategory.Objects },
  { name: IconName.IconLightbulbOff, category: IconCategory.Objects },
  { name: IconName.IconLightbulb, category: IconCategory.Objects },
  { name: IconName.IconLightningChargeFill, category: IconCategory.Weather },
  { name: IconName.IconLightningCharge, category: IconCategory.Weather },
  { name: IconName.IconLightningFill, category: IconCategory.Weather },
  { name: IconName.IconLightning, category: IconCategory.Weather },
  { name: IconName.IconLink45Deg, category: IconCategory.Objects },
  { name: IconName.IconLink, category: IconCategory.Objects },
  { name: IconName.IconMapFill, category: IconCategory.Objects },
  { name: IconName.IconMap, category: IconCategory.Objects },
  { name: IconName.IconMinecartLoaded, category: IconCategory.Objects },
  { name: IconName.IconMinecart, category: IconCategory.Objects },
  { name: IconName.IconMoisture, category: IconCategory.Objects },
  { name: IconName.IconMoonFill, category: IconCategory.Objects },
  { name: IconName.IconMoonStarsFill, category: IconCategory.Objects },
  { name: IconName.IconMoonStars, category: IconCategory.Objects },
  { name: IconName.IconMoon, category: IconCategory.Objects },
  { name: IconName.IconMusicNoteBeamed, category: IconCategory.Objects },
  { name: IconName.IconMusicNoteList, category: IconCategory.Objects },
  { name: IconName.IconMusicNote, category: IconCategory.Objects },
  { name: IconName.IconMusicPlayerFill, category: IconCategory.Objects },
  { name: IconName.IconMusicPlayer, category: IconCategory.Objects },
  { name: IconName.IconNutFill, category: IconCategory.Objects },
  { name: IconName.IconNut, category: IconCategory.Objects },
  { name: IconName.IconOctagonFill, category: IconCategory.Geometries },
  { name: IconName.IconOctagonHalf, category: IconCategory.Geometries },
  { name: IconName.IconOctagon, category: IconCategory.Geometries },
  { name: IconName.IconOutlet, category: IconCategory.Objects },
  { name: IconName.IconPaintBucket, category: IconCategory.Objects },
  { name: IconName.IconPaletteFill, category: IconCategory.Objects },
  { name: IconName.IconPalette, category: IconCategory.Objects },
  { name: IconName.IconPalette2, category: IconCategory.Objects },
  { name: IconName.IconPatchCheckFill, category: IconCategory.Symbols },
  { name: IconName.IconPatchCheck, category: IconCategory.Symbols },
  { name: IconName.IconPatchExclamationFill, category: IconCategory.Symbols },
  { name: IconName.IconPatchExclamation, category: IconCategory.Symbols },
  { name: IconName.IconPatchMinusFill, category: IconCategory.Symbols },
  { name: IconName.IconPatchMinus, category: IconCategory.Symbols },
  { name: IconName.IconPatchPlusFill, category: IconCategory.Symbols },
  { name: IconName.IconPatchPlus, category: IconCategory.Symbols },
  { name: IconName.IconPatchQuestionFill, category: IconCategory.Symbols },
  { name: IconName.IconPatchQuestion, category: IconCategory.Symbols },
  { name: IconName.IconPeaceFill, category: IconCategory.Symbols },
  { name: IconName.IconPeace, category: IconCategory.Symbols },
  { name: IconName.IconPencilFill, category: IconCategory.Objects },
  { name: IconName.IconPencilSquare, category: IconCategory.Objects },
  { name: IconName.IconPencil, category: IconCategory.Objects },
  { name: IconName.IconPentagonFill, category: IconCategory.Geometries },
  { name: IconName.IconPentagonHalf, category: IconCategory.Geometries },
  { name: IconName.IconPentagon, category: IconCategory.Geometries },
  { name: IconName.IconPeopleFill, category: IconCategory.Objects },
  { name: IconName.IconPeople, category: IconCategory.Objects },
  { name: IconName.IconPersonBadgeFill, category: IconCategory.Objects },
  { name: IconName.IconPersonBadge, category: IconCategory.Objects },
  { name: IconName.IconPersonBoundingBox, category: IconCategory.Objects },
  { name: IconName.IconPersonCheckFill, category: IconCategory.Objects },
  { name: IconName.IconPersonCheck, category: IconCategory.Objects },
  { name: IconName.IconPersonCircle, category: IconCategory.Objects },
  { name: IconName.IconPersonDashFill, category: IconCategory.Objects },
  { name: IconName.IconPersonDash, category: IconCategory.Objects },
  { name: IconName.IconPersonFill, category: IconCategory.Objects },
  { name: IconName.IconPersonLinesFill, category: IconCategory.Objects },
  { name: IconName.IconPersonPlusFill, category: IconCategory.Objects },
  { name: IconName.IconPersonPlus, category: IconCategory.Objects },
  { name: IconName.IconPersonSquare, category: IconCategory.Objects },
  { name: IconName.IconPersonXFill, category: IconCategory.Objects },
  { name: IconName.IconPersonX, category: IconCategory.Objects },
  { name: IconName.IconPerson, category: IconCategory.Objects },
  { name: IconName.IconPiggyBankFill, category: IconCategory.Objects },
  { name: IconName.IconPiggyBank, category: IconCategory.Objects },
  { name: IconName.IconPinAngleFill, category: IconCategory.Pins },
  { name: IconName.IconPinAngle, category: IconCategory.Pins },
  { name: IconName.IconPinFill, category: IconCategory.Pins },
  { name: IconName.IconPinMapFill, category: IconCategory.Pins },
  { name: IconName.IconPinMap, category: IconCategory.Pins },
  { name: IconName.IconPin, category: IconCategory.Pins },
  { name: IconName.IconPlugFill, category: IconCategory.Objects },
  { name: IconName.IconPlug, category: IconCategory.Objects },
  { name: IconName.IconPlusCircleDotted, category: IconCategory.Symbols },
  { name: IconName.IconPlusCircleFill, category: IconCategory.Symbols },
  { name: IconName.IconPlusCircle, category: IconCategory.Symbols },
  { name: IconName.IconPlusLg, category: IconCategory.Symbols },
  { name: IconName.IconPlusSquareDotted, category: IconCategory.Symbols },
  { name: IconName.IconPlusSquareFill, category: IconCategory.Symbols },
  { name: IconName.IconPlusSquare, category: IconCategory.Symbols },
  { name: IconName.IconPlus, category: IconCategory.Symbols },
  { name: IconName.IconQuestionCircleFill, category: IconCategory.Symbols },
  { name: IconName.IconQuestionCircle, category: IconCategory.Symbols },
  { name: IconName.IconQuestionDiamondFill, category: IconCategory.Symbols },
  { name: IconName.IconQuestionDiamond, category: IconCategory.Symbols },
  { name: IconName.IconQuestionLg, category: IconCategory.Symbols },
  { name: IconName.IconQuestionOctagonFill, category: IconCategory.Symbols },
  { name: IconName.IconQuestionOctagon, category: IconCategory.Symbols },
  { name: IconName.IconQuestionSquareFill, category: IconCategory.Symbols },
  { name: IconName.IconQuestionSquare, category: IconCategory.Symbols },
  { name: IconName.IconQuestion, category: IconCategory.Symbols },
  { name: IconName.IconRainbow, category: IconCategory.Weather },
  { name: IconName.IconShieldCheck, category: IconCategory.Objects },
  { name: IconName.IconShieldExclamation, category: IconCategory.Objects },
  { name: IconName.IconShieldFillCheck, category: IconCategory.Objects },
  { name: IconName.IconShieldFillExclamation, category: IconCategory.Objects },
  { name: IconName.IconShieldFillMinus, category: IconCategory.Objects },
  { name: IconName.IconShieldFillPlus, category: IconCategory.Objects },
  { name: IconName.IconShieldFillX, category: IconCategory.Objects },
  { name: IconName.IconShieldFill, category: IconCategory.Objects },
  { name: IconName.IconShieldLockFill, category: IconCategory.Objects },
  { name: IconName.IconShieldLock, category: IconCategory.Objects },
  { name: IconName.IconShieldMinus, category: IconCategory.Objects },
  { name: IconName.IconShieldPlus, category: IconCategory.Objects },
  { name: IconName.IconShieldShaded, category: IconCategory.Objects },
  { name: IconName.IconShieldSlashFill, category: IconCategory.Objects },
  { name: IconName.IconShieldSlash, category: IconCategory.Objects },
  { name: IconName.IconShieldX, category: IconCategory.Objects },
  { name: IconName.IconShield, category: IconCategory.Objects },
  { name: IconName.IconSignpost2Fill, category: IconCategory.Objects },
  { name: IconName.IconSignpost2, category: IconCategory.Objects },
  { name: IconName.IconSignpostFill, category: IconCategory.Objects },
  { name: IconName.IconSignpostSplitFill, category: IconCategory.Objects },
  { name: IconName.IconSignpostSplit, category: IconCategory.Objects },
  { name: IconName.IconSignpost, category: IconCategory.Objects },
  { name: IconName.IconSnow, category: IconCategory.Weather },
  { name: IconName.IconSnow2, category: IconCategory.Weather },
  { name: IconName.IconSnow3, category: IconCategory.Weather },
  { name: IconName.IconSuitClubFill, category: IconCategory.Symbols },
  { name: IconName.IconSuitClub, category: IconCategory.Symbols },
  { name: IconName.IconSuitDiamondFill, category: IconCategory.Symbols },
  { name: IconName.IconSuitDiamond, category: IconCategory.Symbols },
  { name: IconName.IconSuitHeartFill, category: IconCategory.Symbols },
  { name: IconName.IconSuitHeart, category: IconCategory.Symbols },
  { name: IconName.IconSuitSpadeFill, category: IconCategory.Symbols },
  { name: IconName.IconSuitSpade, category: IconCategory.Symbols },
  { name: IconName.IconSunFill, category: IconCategory.Weather },
  { name: IconName.IconSun, category: IconCategory.Weather },
  { name: IconName.IconSunglasses, category: IconCategory.Weather },
  { name: IconName.IconSunriseFill, category: IconCategory.Weather },
  { name: IconName.IconSunrise, category: IconCategory.Weather },
  { name: IconName.IconSunsetFill, category: IconCategory.Weather },
  { name: IconName.IconSunset, category: IconCategory.Weather },
  { name: IconName.IconTagFill, category: IconCategory.Objects },
  { name: IconName.IconTag, category: IconCategory.Objects },
  { name: IconName.IconTagsFill, category: IconCategory.Objects },
  { name: IconName.IconTags, category: IconCategory.Objects },
  { name: IconName.IconTelephoneFill, category: IconCategory.Objects },
  { name: IconName.IconTelephoneForwardFill, category: IconCategory.Objects },
  { name: IconName.IconTelephoneForward, category: IconCategory.Objects },
  { name: IconName.IconTelephoneInboundFill, category: IconCategory.Objects },
  { name: IconName.IconTelephoneInbound, category: IconCategory.Objects },
  { name: IconName.IconTelephoneMinusFill, category: IconCategory.Objects },
  { name: IconName.IconTelephoneMinus, category: IconCategory.Objects },
  { name: IconName.IconTelephoneOutboundFill, category: IconCategory.Objects },
  { name: IconName.IconTelephoneOutbound, category: IconCategory.Objects },
  { name: IconName.IconTelephonePlusFill, category: IconCategory.Objects },
  { name: IconName.IconTelephonePlus, category: IconCategory.Objects },
  { name: IconName.IconTelephoneXFill, category: IconCategory.Objects },
  { name: IconName.IconTelephoneX, category: IconCategory.Objects },
  { name: IconName.IconTelephone, category: IconCategory.Objects },
  { name: IconName.IconThermometerHalf, category: IconCategory.Objects },
  { name: IconName.IconThermometerHigh, category: IconCategory.Objects },
  { name: IconName.IconThermometerLow, category: IconCategory.Objects },
  { name: IconName.IconThermometerSnow, category: IconCategory.Objects },
  { name: IconName.IconThermometerSun, category: IconCategory.Objects },
  { name: IconName.IconThermometer, category: IconCategory.Objects },
  { name: IconName.IconTrashFill, category: IconCategory.Objects },
  { name: IconName.IconTrash, category: IconCategory.Objects },
  { name: IconName.IconTrash2Fill, category: IconCategory.Objects },
  { name: IconName.IconTrash2, category: IconCategory.Objects },
  { name: IconName.IconTreeFill, category: IconCategory.Objects },
  { name: IconName.IconTree, category: IconCategory.Objects },
  { name: IconName.IconTrophyFill, category: IconCategory.Objects },
  { name: IconName.IconTrophy, category: IconCategory.Objects },
  { name: IconName.IconTropicalStorm, category: IconCategory.Objects },
  { name: IconName.IconTruckFlatbed, category: IconCategory.Objects },
  { name: IconName.IconTruck, category: IconCategory.Objects },
  { name: IconName.IconTsunami, category: IconCategory.Objects },
  { name: IconName.IconUmbrellaFill, category: IconCategory.Objects },
  { name: IconName.IconUmbrella, category: IconCategory.Objects },
  { name: IconName.IconUnion, category: IconCategory.Objects },
  { name: IconName.IconUnlockFill, category: IconCategory.Objects },
  { name: IconName.IconUnlock, category: IconCategory.Objects },
  { name: IconName.IconWatch, category: IconCategory.Objects },
  { name: IconName.IconWater, category: IconCategory.Objects },
  { name: IconName.IconWifi1, category: IconCategory.Objects },
  { name: IconName.IconWifi2, category: IconCategory.Objects },
  { name: IconName.IconWifiOff, category: IconCategory.Objects },
  { name: IconName.IconWifi, category: IconCategory.Objects },
  { name: IconName.IconWind, category: IconCategory.Objects },
  { name: IconName.IconXCircleFill, category: IconCategory.Symbols },
  { name: IconName.IconXCircle, category: IconCategory.Symbols },
  { name: IconName.IconXDiamondFill, category: IconCategory.Geometries },
  { name: IconName.IconXDiamond, category: IconCategory.Geometries },
  { name: IconName.IconXLg, category: IconCategory.Symbols },
  { name: IconName.IconXOctagonFill, category: IconCategory.Symbols },
  { name: IconName.IconXOctagon, category: IconCategory.Symbols },
  { name: IconName.IconXSquareFill, category: IconCategory.Symbols },
  { name: IconName.IconXSquare, category: IconCategory.Symbols },
  { name: IconName.IconX, category: IconCategory.Symbols },
  { name: IconName.IconZoomIn, category: IconCategory.Objects },
  { name: IconName.IconZoomOut, category: IconCategory.Objects },
  { name: IconName.IconIso7001RedCross, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001RedSlash, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp001, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp002, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp003, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp004, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp005, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp006, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp007, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp008, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp009, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp010, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp011, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp012, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp013, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp014, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp015, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp016, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp017, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp018, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiBp019, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf001, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf002, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf003, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf004, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf005, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf006, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf007, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf008, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf009, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf010, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf011, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf012, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf014, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf015, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf016, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf017, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf018, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf019, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf020, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf021, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiCf022, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf001, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf002, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf003, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf004, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf005, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf006, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf007, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf008, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf009, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf010, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf011, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf012, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf013, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf014, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf015, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf016, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf017, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf018, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf019, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf020, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf021, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf022, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf023, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf024, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf025, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf026, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf027, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf028, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf029, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf030, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf031, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf032, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf033, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf034, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf036, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf037, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf043, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf044, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf045, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf046, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf047, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf048, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf049, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf050, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf051, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf054, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf055, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf056, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf057, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf058, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf059, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf062, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf063, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf064, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf065, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf066, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf067, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf068, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf069, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf070, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf072, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf073, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf074, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf075, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf076, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf077, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf078, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf079, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf080, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf081, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiPf082, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiSa001, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiSa002, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiSa003, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiSa004, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc001, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc002, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc003, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc004, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc005, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc006, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc007, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc008, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc009, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc010, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc011, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc012, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc013, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc014, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc015, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc016, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc017, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTc018, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf001, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf002, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf003, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf004, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf005, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf006, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf007, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf008, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf009, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf010, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf011, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf012, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf013, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf014, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf015, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf016, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf017, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf018, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf019, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf020, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf021, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf022, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf023, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf024, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf025, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf026, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf027, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf028, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf029, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf030, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf031, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf032, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf033, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf034, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf035, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf036, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf037, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf038, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf039, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf040, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf041, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf042, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf043, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconIso7001PiTf044, category: IconCategory.Iso7001, staticColors: true },
  { name: IconName.IconDin48442DP017, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E002New, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E003FirstAidSign, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E004, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E007, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E008, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E009, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E010, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E011, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E012, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E013, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E016, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E017, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E018, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010E019, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010F001, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010F002, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010F003, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010F004, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010F005, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010F006, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010F007, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M001, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M002, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M003, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M004, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M005, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M006, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M007, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M008, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M009, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M010, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M011, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M012, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M013, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M014, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M015, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M016, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M017, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M018, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M019, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M020, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M021, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M022, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M023, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M024, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M025, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010M026, category: IconCategory.Iso7010, staticColors: true },
  {
    name: IconName.IconIso7010M027MandatoryInstallOrCheckGuard,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M028MandatoryInstallLocksAndKeepLocked,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M029MandatorySoundYourHorn,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M030MandatoryPlaceTrashInTheBin,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M031MandatoryUseGuardToProtectFromInjuryFromTheTableSaw,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M032MandatoryUseFootwearWithAntiStaticOrAntiSparkFeatures,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M033MandatoryLowerSafetyRestrainingBarOnSkiChairlift,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M034MandatoryRaiseSafetyRestrainingBarOnSkiChairlift,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M035MandatoryExitTowpathAfterFalling,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M036MandatoryKeepSkiTipsUp,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M037MandatoryCloseAndSecureTheHatchLifeboatLaunchSequence,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M038MandatoryStartTheMotorLifeboatLaunchSequence,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M039MandatoryLowerTheLifeboatToTheWaterLifeboatLaunchSequence,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M040MandatoryLowerTheLifeboatToTheWaterLifeboatLaunchSequence,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M041MandatoryLowerTheRescueBoatToTheWaterLifeboatLaunchSequence,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M042MandatoryReleaseTheHooksHoldingTheLifeboatLifeboatLaunchSequence,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M043MandatoryStartTheWaterSprayForTheLifeboatLifeboatLaunchSequence,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M044MandatoryStartTheAirSupplyForTheLifeboatLifeboatLaunchSequence,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M045MandatoryReleaseTheRopesGripesLifeboatLaunchSequence,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M046MandatorySecureGasCylinders,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M047MandatoryUseBreathingEquipmentScba,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  { name: IconName.IconIso7010M048GasdetektorBenutzen, category: IconCategory.Iso7010, staticColors: true },
  {
    name: IconName.IconIso7010M049MandatoryUsePersonalProtectiveEquipmentForSport,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M050MandatoryExitSledTobogganToTheLeft,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M051MandatoryExitSledTobogganToTheRight,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M052MandatoryKeepASafeDistanceBetweenSledsToboggans,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M053MandatoryWearPersonalFloatationDevicePfdLifejacket,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010M054SuperviseChildrenDuringAquaticActivities,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  { name: IconName.IconIso7010M055, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P001, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P002, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P003, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P004, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P005, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P006, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P007, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P008, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P010, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P011, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P012, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P013, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P014, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P015, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P017, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P018, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P019, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P020, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P021, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P022, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P023, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P024, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P025, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P026, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P027, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P028, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P029, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P030, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P031, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P032, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P033, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010P034, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010SafeConditionArrow1, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010SafeConditionArrow2, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W001, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W002, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W003, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W004, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W005, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W006, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W007, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W008, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W009, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W010, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W011, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W012, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W013, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W014, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W015, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W016, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W017, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W018, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W019, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W020, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W021, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W022, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W023, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W024, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W025, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W026, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W027, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W028, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W029, category: IconCategory.Iso7010, staticColors: true },
  {
    name: IconName.IconIso7010W031WarningHandInjuryFromWorkpieceMovingInPressBrakeMachinery,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  { name: IconName.IconIso7010W035, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W039, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W040WarningRoofAvalanche, category: IconCategory.Iso7010, staticColors: true },
  {
    name: IconName.IconIso7010W041WarningBreathingHazard,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  { name: IconName.IconIso7010W042WarningArcFlashHazard, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W043WarningThinIce, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W044WarningSlipway, category: IconCategory.Iso7010, staticColors: true },
  {
    name: IconName.IconIso7010W045WarningWaterSkiingOrTowedWaterActivityArea,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  { name: IconName.IconIso7010W046WarningSurfing, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W047WarningDeepWater, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W048WarningShallowWater, category: IconCategory.Iso7010, staticColors: true },
  {
    name: IconName.IconIso7010W049WarningSubmergedObjects,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010W050WarningSuddenDropInSwimmingPool,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010W051WarningNoGuardRailAndUnprotectedEdges,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010W052WarningUnstableCliffEdge,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  { name: IconName.IconIso7010W053WarningUnstableCliff, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W054WarningSharks, category: IconCategory.Iso7010, staticColors: true },
  {
    name: IconName.IconIso7010W055WarningSewageEffluentOutfall,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010W056WarningTsunamiHazardZone,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  { name: IconName.IconIso7010W057WarningStrongCurrents, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W058WarningBoatingArea, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W059SandYachting, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W060WarningIncomingTides, category: IconCategory.Iso7010, staticColors: true },
  {
    name: IconName.IconIso7010W061WarningQuicksandMudDeepMudOrSilt,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  { name: IconName.IconIso7010W062WarningKiteSurfing, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W063WarningParasailing, category: IconCategory.Iso7010, staticColors: true },
  { name: IconName.IconIso7010W064WarningStrongWinds, category: IconCategory.Iso7010, staticColors: true },
  {
    name: IconName.IconIso7010W065WarningHighSurfOrLargeBreakingWaves,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010W066WarningSuddenDropToDeepWater,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  {
    name: IconName.IconIso7010W067WarningAlligatorsCaymansAndCrocodiles,
    category: IconCategory.Iso7010,
    staticColors: true,
  },
  { name: IconName.IconSignFirstAid, category: IconCategory.Iso7010, staticColors: true },
];

/**
 * Default icon is used when an icon is not found
 */
export const DefaultIcon = AllPointIcons[0];
