/**
 * Copyright © 2023 Rémi Pace.
 * This file is part of Abc-Map.
 *
 * Abc-Map is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of
 * the License, or (at your option) any later version.
 *
 * Abc-Map is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General
 * Public License along with Abc-Map. If not, see <https://www.gnu.org/licenses/>.
 */

/* eslint-disable max-len */

export enum IconName {
  Icon0CircleFill = 'twbs/0_circle-fill.svg',
  Icon0CircleHalf = 'twbs/0_circle-half.svg',
  Icon0CircleSquare = 'twbs/0_circle-square.svg',
  Icon0Circle = 'twbs/0_circle.svg',
  Icon0SquareFill = 'twbs/0_square-fill.svg',
  Icon0SquareHalf = 'twbs/0_square-half.svg',
  Icon0Square = 'twbs/0_square.svg',
  Icon0StarFill = 'twbs/0_star-fill.svg',
  Icon0StarHalf = 'twbs/0_star-half.svg',
  Icon0Star = 'twbs/0_star.svg',
  Icon0Stars = 'twbs/0_stars.svg',
  Icon0TriangleFill = 'twbs/0_triangle-fill.svg',
  Icon0TriangleHalf = 'twbs/0_triangle-half.svg',
  Icon0Triangle = 'twbs/0_triangle.svg',
  IconArrow90DegDown = 'twbs/arrow-90deg-down.svg',
  IconArrow90DegLeft = 'twbs/arrow-90deg-left.svg',
  IconArrow90DegRight = 'twbs/arrow-90deg-right.svg',
  IconArrow90DegUp = 'twbs/arrow-90deg-up.svg',
  IconArrowClockwise = 'twbs/arrow-clockwise.svg',
  IconArrowCounterclockwise = 'twbs/arrow-counterclockwise.svg',
  IconArrowDownCircleFill = 'twbs/arrow-down-circle-fill.svg',
  IconArrowDownCircle = 'twbs/arrow-down-circle.svg',
  IconArrowDownLeftCircleFill = 'twbs/arrow-down-left-circle-fill.svg',
  IconArrowDownLeftCircle = 'twbs/arrow-down-left-circle.svg',
  IconArrowDownLeftSquareFill = 'twbs/arrow-down-left-square-fill.svg',
  IconArrowDownLeftSquare = 'twbs/arrow-down-left-square.svg',
  IconArrowDownLeft = 'twbs/arrow-down-left.svg',
  IconArrowDownRightCircleFill = 'twbs/arrow-down-right-circle-fill.svg',
  IconArrowDownRightCircle = 'twbs/arrow-down-right-circle.svg',
  IconArrowDownRightSquareFill = 'twbs/arrow-down-right-square-fill.svg',
  IconArrowDownRightSquare = 'twbs/arrow-down-right-square.svg',
  IconArrowDownRight = 'twbs/arrow-down-right.svg',
  IconArrowDownShort = 'twbs/arrow-down-short.svg',
  IconArrowDownSquareFill = 'twbs/arrow-down-square-fill.svg',
  IconArrowDownSquare = 'twbs/arrow-down-square.svg',
  IconArrowDownUp = 'twbs/arrow-down-up.svg',
  IconArrowDown = 'twbs/arrow-down.svg',
  IconArrowLeftCircleFill = 'twbs/arrow-left-circle-fill.svg',
  IconArrowLeftCircle = 'twbs/arrow-left-circle.svg',
  IconArrowLeftRight = 'twbs/arrow-left-right.svg',
  IconArrowLeftShort = 'twbs/arrow-left-short.svg',
  IconArrowLeftSquareFill = 'twbs/arrow-left-square-fill.svg',
  IconArrowLeftSquare = 'twbs/arrow-left-square.svg',
  IconArrowLeft = 'twbs/arrow-left.svg',
  IconArrowRepeat = 'twbs/arrow-repeat.svg',
  IconArrowReturnLeft = 'twbs/arrow-return-left.svg',
  IconArrowReturnRight = 'twbs/arrow-return-right.svg',
  IconArrowRightCircleFill = 'twbs/arrow-right-circle-fill.svg',
  IconArrowRightCircle = 'twbs/arrow-right-circle.svg',
  IconArrowRightShort = 'twbs/arrow-right-short.svg',
  IconArrowRightSquareFill = 'twbs/arrow-right-square-fill.svg',
  IconArrowRightSquare = 'twbs/arrow-right-square.svg',
  IconArrowRight = 'twbs/arrow-right.svg',
  IconArrowUpCircleFill = 'twbs/arrow-up-circle-fill.svg',
  IconArrowUpCircle = 'twbs/arrow-up-circle.svg',
  IconArrowUpLeftCircleFill = 'twbs/arrow-up-left-circle-fill.svg',
  IconArrowUpLeftCircle = 'twbs/arrow-up-left-circle.svg',
  IconArrowUpLeftSquareFill = 'twbs/arrow-up-left-square-fill.svg',
  IconArrowUpLeftSquare = 'twbs/arrow-up-left-square.svg',
  IconArrowUpLeft = 'twbs/arrow-up-left.svg',
  IconArrowUpRightCircleFill = 'twbs/arrow-up-right-circle-fill.svg',
  IconArrowUpRightCircle = 'twbs/arrow-up-right-circle.svg',
  IconArrowUpRightSquareFill = 'twbs/arrow-up-right-square-fill.svg',
  IconArrowUpRightSquare = 'twbs/arrow-up-right-square.svg',
  IconArrowUpRight = 'twbs/arrow-up-right.svg',
  IconArrowUpShort = 'twbs/arrow-up-short.svg',
  IconArrowUpSquareFill = 'twbs/arrow-up-square-fill.svg',
  IconArrowUpSquare = 'twbs/arrow-up-square.svg',
  IconArrowUp = 'twbs/arrow-up.svg',
  IconArrowsAngleContract = 'twbs/arrows-angle-contract.svg',
  IconArrowsFullscreen = 'twbs/arrows-fullscreen.svg',
  IconArrowsMove = 'twbs/arrows-move.svg',
  IconBagCheckFill = 'twbs/bag-check-fill.svg',
  IconBagCheck = 'twbs/bag-check.svg',
  IconBagDashFill = 'twbs/bag-dash-fill.svg',
  IconBagDash = 'twbs/bag-dash.svg',
  IconBagFill = 'twbs/bag-fill.svg',
  IconBagPlusFill = 'twbs/bag-plus-fill.svg',
  IconBagPlus = 'twbs/bag-plus.svg',
  IconBagXFill = 'twbs/bag-x-fill.svg',
  IconBagX = 'twbs/bag-x.svg',
  IconBag = 'twbs/bag.svg',
  IconBank = 'twbs/bank.svg',
  IconBank2 = 'twbs/bank2.svg',
  IconBasketFill = 'twbs/basket-fill.svg',
  IconBasket = 'twbs/basket.svg',
  IconBasket2Fill = 'twbs/basket2-fill.svg',
  IconBasket2 = 'twbs/basket2.svg',
  IconBasket3Fill = 'twbs/basket3-fill.svg',
  IconBasket3 = 'twbs/basket3.svg',
  IconBicycle = 'twbs/bicycle.svg',
  IconBinocularsFill = 'twbs/binoculars-fill.svg',
  IconBinoculars = 'twbs/binoculars.svg',
  IconBookFill = 'twbs/book-fill.svg',
  IconBookHalf = 'twbs/book-half.svg',
  IconBoxArrowDownLeft = 'twbs/box-arrow-down-left.svg',
  IconBoxArrowDownRight = 'twbs/box-arrow-down-right.svg',
  IconBoxArrowInDownLeft = 'twbs/box-arrow-in-down-left.svg',
  IconBoxArrowInDownRight = 'twbs/box-arrow-in-down-right.svg',
  IconBoxArrowInDown = 'twbs/box-arrow-in-down.svg',
  IconBoxArrowInLeft = 'twbs/box-arrow-in-left.svg',
  IconBoxArrowInRight = 'twbs/box-arrow-in-right.svg',
  IconBoxArrowInUpLeft = 'twbs/box-arrow-in-up-left.svg',
  IconBoxArrowInUpRight = 'twbs/box-arrow-in-up-right.svg',
  IconBoxArrowInUp = 'twbs/box-arrow-in-up.svg',
  IconBoxArrowLeft = 'twbs/box-arrow-left.svg',
  IconBoxArrowRight = 'twbs/box-arrow-right.svg',
  IconBoxArrowUpLeft = 'twbs/box-arrow-up-left.svg',
  IconBoxArrowUpRight = 'twbs/box-arrow-up-right.svg',
  IconBoxArrowUp = 'twbs/box-arrow-up.svg',
  IconBoxSeam = 'twbs/box-seam.svg',
  IconBox = 'twbs/box.svg',
  IconBrightnessAltHighFill = 'twbs/brightness-alt-high-fill.svg',
  IconBrightnessAltHigh = 'twbs/brightness-alt-high.svg',
  IconBrightnessAltLowFill = 'twbs/brightness-alt-low-fill.svg',
  IconBrightnessAltLow = 'twbs/brightness-alt-low.svg',
  IconBrightnessHighFill = 'twbs/brightness-high-fill.svg',
  IconBrightnessHigh = 'twbs/brightness-high.svg',
  IconBrightnessLowFill = 'twbs/brightness-low-fill.svg',
  IconBrightnessLow = 'twbs/brightness-low.svg',
  IconBroadcastPin = 'twbs/broadcast-pin.svg',
  IconBroadcast = 'twbs/broadcast.svg',
  IconBucketFill = 'twbs/bucket-fill.svg',
  IconBucket = 'twbs/bucket.svg',
  IconBugFill = 'twbs/bug-fill.svg',
  IconBug = 'twbs/bug.svg',
  IconCameraFill = 'twbs/camera-fill.svg',
  IconCameraReelsFill = 'twbs/camera-reels-fill.svg',
  IconCameraReels = 'twbs/camera-reels.svg',
  IconCameraVideoFill = 'twbs/camera-video-fill.svg',
  IconCameraVideoOffFill = 'twbs/camera-video-off-fill.svg',
  IconCameraVideoOff = 'twbs/camera-video-off.svg',
  IconCameraVideo = 'twbs/camera-video.svg',
  IconCamera = 'twbs/camera.svg',
  IconCamera2 = 'twbs/camera2.svg',
  IconCartCheckFill = 'twbs/cart-check-fill.svg',
  IconCartCheck = 'twbs/cart-check.svg',
  IconCartDashFill = 'twbs/cart-dash-fill.svg',
  IconCartDash = 'twbs/cart-dash.svg',
  IconCartFill = 'twbs/cart-fill.svg',
  IconCartPlusFill = 'twbs/cart-plus-fill.svg',
  IconCartPlus = 'twbs/cart-plus.svg',
  IconCartXFill = 'twbs/cart-x-fill.svg',
  IconCartX = 'twbs/cart-x.svg',
  IconCart = 'twbs/cart.svg',
  IconCart2 = 'twbs/cart2.svg',
  IconCart3 = 'twbs/cart3.svg',
  IconCart4 = 'twbs/cart4.svg',
  IconCashCoin = 'twbs/cash-coin.svg',
  IconCashStack = 'twbs/cash-stack.svg',
  IconCash = 'twbs/cash.svg',
  IconCloudArrowDownFill = 'twbs/cloud-arrow-down-fill.svg',
  IconCloudArrowDown = 'twbs/cloud-arrow-down.svg',
  IconCloudArrowUpFill = 'twbs/cloud-arrow-up-fill.svg',
  IconCloudArrowUp = 'twbs/cloud-arrow-up.svg',
  IconCloudCheckFill = 'twbs/cloud-check-fill.svg',
  IconCloudCheck = 'twbs/cloud-check.svg',
  IconCloudDrizzleFill = 'twbs/cloud-drizzle-fill.svg',
  IconCloudDrizzle = 'twbs/cloud-drizzle.svg',
  IconCloudFill = 'twbs/cloud-fill.svg',
  IconCloudFogFill = 'twbs/cloud-fog-fill.svg',
  IconCloudFog = 'twbs/cloud-fog.svg',
  IconCloudFog2Fill = 'twbs/cloud-fog2-fill.svg',
  IconCloudFog2 = 'twbs/cloud-fog2.svg',
  IconCloudHailFill = 'twbs/cloud-hail-fill.svg',
  IconCloudHail = 'twbs/cloud-hail.svg',
  IconCloudHaze1 = 'twbs/cloud-haze-1.svg',
  IconCloudHazeFill = 'twbs/cloud-haze-fill.svg',
  IconCloudHaze = 'twbs/cloud-haze.svg',
  IconCloudHaze2Fill = 'twbs/cloud-haze2-fill.svg',
  IconCloudLightningFill = 'twbs/cloud-lightning-fill.svg',
  IconCloudLightningRainFill = 'twbs/cloud-lightning-rain-fill.svg',
  IconCloudLightningRain = 'twbs/cloud-lightning-rain.svg',
  IconCloudLightning = 'twbs/cloud-lightning.svg',
  IconCloudMinusFill = 'twbs/cloud-minus-fill.svg',
  IconCloudMinus = 'twbs/cloud-minus.svg',
  IconCloudMoonFill = 'twbs/cloud-moon-fill.svg',
  IconCloudMoon = 'twbs/cloud-moon.svg',
  IconCloudPlusFill = 'twbs/cloud-plus-fill.svg',
  IconCloudPlus = 'twbs/cloud-plus.svg',
  IconCloudRainFill = 'twbs/cloud-rain-fill.svg',
  IconCloudRainHeavyFill = 'twbs/cloud-rain-heavy-fill.svg',
  IconCloudRainHeavy = 'twbs/cloud-rain-heavy.svg',
  IconCloudRain = 'twbs/cloud-rain.svg',
  IconCloudSlashFill = 'twbs/cloud-slash-fill.svg',
  IconCloudSlash = 'twbs/cloud-slash.svg',
  IconCloudSleetFill = 'twbs/cloud-sleet-fill.svg',
  IconCloudSleet = 'twbs/cloud-sleet.svg',
  IconCloudSnowFill = 'twbs/cloud-snow-fill.svg',
  IconCloudSnow = 'twbs/cloud-snow.svg',
  IconCloudSunFill = 'twbs/cloud-sun-fill.svg',
  IconCloudSun = 'twbs/cloud-sun.svg',
  IconCloudUploadFill = 'twbs/cloud-upload-fill.svg',
  IconCloudUpload = 'twbs/cloud-upload.svg',
  IconCompassFill = 'twbs/compass-fill.svg',
  IconCompass = 'twbs/compass.svg',
  IconCreditCard2BackFill = 'twbs/credit-card-2-back-fill.svg',
  IconCreditCard2Back = 'twbs/credit-card-2-back.svg',
  IconCreditCard2FrontFill = 'twbs/credit-card-2-front-fill.svg',
  IconCreditCard2Front = 'twbs/credit-card-2-front.svg',
  IconCreditCardFill = 'twbs/credit-card-fill.svg',
  IconCreditCard = 'twbs/credit-card.svg',
  IconCupFill = 'twbs/cup-fill.svg',
  IconCupStraw = 'twbs/cup-straw.svg',
  IconCup = 'twbs/cup.svg',
  IconCurrencyBitcoin = 'twbs/currency-bitcoin.svg',
  IconCurrencyDollar = 'twbs/currency-dollar.svg',
  IconCurrencyEuro = 'twbs/currency-euro.svg',
  IconCurrencyExchange = 'twbs/currency-exchange.svg',
  IconCurrencyPound = 'twbs/currency-pound.svg',
  IconCurrencyYen = 'twbs/currency-yen.svg',
  IconDiamondFill = 'twbs/diamond-fill.svg',
  IconDiamondHalf = 'twbs/diamond-half.svg',
  IconDiamond = 'twbs/diamond.svg',
  IconDice1Fill = 'twbs/dice-1-fill.svg',
  IconDice1 = 'twbs/dice-1.svg',
  IconDice2Fill = 'twbs/dice-2-fill.svg',
  IconDice2 = 'twbs/dice-2.svg',
  IconDice3Fill = 'twbs/dice-3-fill.svg',
  IconDice3 = 'twbs/dice-3.svg',
  IconDice4Fill = 'twbs/dice-4-fill.svg',
  IconDice4 = 'twbs/dice-4.svg',
  IconDice5Fill = 'twbs/dice-5-fill.svg',
  IconDice5 = 'twbs/dice-5.svg',
  IconDice6Fill = 'twbs/dice-6-fill.svg',
  IconDice6 = 'twbs/dice-6.svg',
  IconDiscFill = 'twbs/disc-fill.svg',
  IconDisc = 'twbs/disc.svg',
  IconDiscord = 'twbs/discord.svg',
  IconDoorClosedFill = 'twbs/door-closed-fill.svg',
  IconDoorClosed = 'twbs/door-closed.svg',
  IconDoorOpenFill = 'twbs/door-open-fill.svg',
  IconDoorOpen = 'twbs/door-open.svg',
  IconDropletFill = 'twbs/droplet-fill.svg',
  IconDropletHalf = 'twbs/droplet-half.svg',
  IconEggFill = 'twbs/egg-fill.svg',
  IconEggFried = 'twbs/egg-fried.svg',
  IconEgg = 'twbs/egg.svg',
  IconEmojiAngryFill = 'twbs/emoji-angry-fill.svg',
  IconEmojiAngry = 'twbs/emoji-angry.svg',
  IconEmojiDizzyFill = 'twbs/emoji-dizzy-fill.svg',
  IconEmojiDizzy = 'twbs/emoji-dizzy.svg',
  IconEmojiExpressionlessFill = 'twbs/emoji-expressionless-fill.svg',
  IconEmojiExpressionless = 'twbs/emoji-expressionless.svg',
  IconEmojiFrownFill = 'twbs/emoji-frown-fill.svg',
  IconEmojiFrown = 'twbs/emoji-frown.svg',
  IconEmojiHeartEyesFill = 'twbs/emoji-heart-eyes-fill.svg',
  IconEmojiHeartEyes = 'twbs/emoji-heart-eyes.svg',
  IconEmojiLaughingFill = 'twbs/emoji-laughing-fill.svg',
  IconEmojiLaughing = 'twbs/emoji-laughing.svg',
  IconEmojiNeutralFill = 'twbs/emoji-neutral-fill.svg',
  IconEmojiNeutral = 'twbs/emoji-neutral.svg',
  IconEmojiSmileFill = 'twbs/emoji-smile-fill.svg',
  IconEmojiSmileUpsideDownFill = 'twbs/emoji-smile-upside-down-fill.svg',
  IconEmojiSmileUpsideDown = 'twbs/emoji-smile-upside-down.svg',
  IconEmojiSmile = 'twbs/emoji-smile.svg',
  IconEmojiSunglassesFill = 'twbs/emoji-sunglasses-fill.svg',
  IconEmojiSunglasses = 'twbs/emoji-sunglasses.svg',
  IconEmojiWinkFill = 'twbs/emoji-wink-fill.svg',
  IconEmojiWink = 'twbs/emoji-wink.svg',
  IconEnvelopeFill = 'twbs/envelope-fill.svg',
  IconEnvelopeOpenFill = 'twbs/envelope-open-fill.svg',
  IconEnvelopeOpen = 'twbs/envelope-open.svg',
  IconEnvelope = 'twbs/envelope.svg',
  IconExclamationCircleFill = 'twbs/exclamation-circle-fill.svg',
  IconExclamationCircle = 'twbs/exclamation-circle.svg',
  IconExclamationDiamondFill = 'twbs/exclamation-diamond-fill.svg',
  IconExclamationDiamond = 'twbs/exclamation-diamond.svg',
  IconExclamationLg = 'twbs/exclamation-lg.svg',
  IconExclamationOctagonFill = 'twbs/exclamation-octagon-fill.svg',
  IconExclamationOctagon = 'twbs/exclamation-octagon.svg',
  IconExclamationSquareFill = 'twbs/exclamation-square-fill.svg',
  IconExclamationSquare = 'twbs/exclamation-square.svg',
  IconExclamationTriangleFill = 'twbs/exclamation-triangle-fill.svg',
  IconExclamationTriangle = 'twbs/exclamation-triangle.svg',
  IconExclamation = 'twbs/exclamation.svg',
  IconExclude = 'twbs/exclude.svg',
  IconEyeFill = 'twbs/eye-fill.svg',
  IconEyeSlashFill = 'twbs/eye-slash-fill.svg',
  IconEyeSlash = 'twbs/eye-slash.svg',
  IconEye = 'twbs/eye.svg',
  IconEyedropper = 'twbs/eyedropper.svg',
  IconEyeglasses = 'twbs/eyeglasses.svg',
  IconFlower1 = 'twbs/flower1.svg',
  IconFlower2 = 'twbs/flower2.svg',
  IconFlower3 = 'twbs/flower3.svg',
  IconFunnelFill = 'twbs/funnel-fill.svg',
  IconFunnel = 'twbs/funnel.svg',
  IconGearFill = 'twbs/gear-fill.svg',
  IconGearWideConnected = 'twbs/gear-wide-connected.svg',
  IconGearWide = 'twbs/gear-wide.svg',
  IconGear = 'twbs/gear.svg',
  IconGem = 'twbs/gem.svg',
  IconGeoAltFill = 'twbs/geo-alt-fill.svg',
  IconGeoAlt = 'twbs/geo-alt.svg',
  IconGeoFill = 'twbs/geo-fill.svg',
  IconGeo = 'twbs/geo.svg',
  IconHammer = 'twbs/hammer.svg',
  IconHandbagFill = 'twbs/handbag-fill.svg',
  IconHandbag = 'twbs/handbag.svg',
  IconHeartFill = 'twbs/heart-fill.svg',
  IconHeartHalf = 'twbs/heart-half.svg',
  IconHeart = 'twbs/heart.svg',
  IconHeptagonFill = 'twbs/heptagon-fill.svg',
  IconHeptagonHalf = 'twbs/heptagon-half.svg',
  IconHeptagon = 'twbs/heptagon.svg',
  IconHouseDoorFill = 'twbs/house-door-fill.svg',
  IconHouseDoor = 'twbs/house-door.svg',
  IconHouseFill = 'twbs/house-fill.svg',
  IconHouse = 'twbs/house.svg',
  IconHurricane = 'twbs/hurricane.svg',
  IconInfoCircleFill = 'twbs/info-circle-fill.svg',
  IconInfoCircle = 'twbs/info-circle.svg',
  IconInfoLg = 'twbs/info-lg.svg',
  IconInfoSquareFill = 'twbs/info-square-fill.svg',
  IconInfoSquare = 'twbs/info-square.svg',
  IconInfo = 'twbs/info.svg',
  IconJournalAlbum = 'twbs/journal-album.svg',
  IconJournalCheck = 'twbs/journal-check.svg',
  IconLadder = 'twbs/ladder.svg',
  IconLifePreserver = 'twbs/life-preserver.svg',
  IconLightbulbFill = 'twbs/lightbulb-fill.svg',
  IconLightbulbOffFill = 'twbs/lightbulb-off-fill.svg',
  IconLightbulbOff = 'twbs/lightbulb-off.svg',
  IconLightbulb = 'twbs/lightbulb.svg',
  IconLightningChargeFill = 'twbs/lightning-charge-fill.svg',
  IconLightningCharge = 'twbs/lightning-charge.svg',
  IconLightningFill = 'twbs/lightning-fill.svg',
  IconLightning = 'twbs/lightning.svg',
  IconLink45Deg = 'twbs/link-45deg.svg',
  IconLink = 'twbs/link.svg',
  IconLinkedin = 'twbs/linkedin.svg',
  IconMapFill = 'twbs/map-fill.svg',
  IconMap = 'twbs/map.svg',
  IconMinecartLoaded = 'twbs/minecart-loaded.svg',
  IconMinecart = 'twbs/minecart.svg',
  IconMoisture = 'twbs/moisture.svg',
  IconMoonFill = 'twbs/moon-fill.svg',
  IconMoonStarsFill = 'twbs/moon-stars-fill.svg',
  IconMoonStars = 'twbs/moon-stars.svg',
  IconMoon = 'twbs/moon.svg',
  IconMusicNoteBeamed = 'twbs/music-note-beamed.svg',
  IconMusicNoteList = 'twbs/music-note-list.svg',
  IconMusicNote = 'twbs/music-note.svg',
  IconMusicPlayerFill = 'twbs/music-player-fill.svg',
  IconMusicPlayer = 'twbs/music-player.svg',
  IconNutFill = 'twbs/nut-fill.svg',
  IconNut = 'twbs/nut.svg',
  IconOctagonFill = 'twbs/octagon-fill.svg',
  IconOctagonHalf = 'twbs/octagon-half.svg',
  IconOctagon = 'twbs/octagon.svg',
  IconOutlet = 'twbs/outlet.svg',
  IconPaintBucket = 'twbs/paint-bucket.svg',
  IconPaletteFill = 'twbs/palette-fill.svg',
  IconPalette = 'twbs/palette.svg',
  IconPalette2 = 'twbs/palette2.svg',
  IconPatchCheckFill = 'twbs/patch-check-fill.svg',
  IconPatchCheck = 'twbs/patch-check.svg',
  IconPatchExclamationFill = 'twbs/patch-exclamation-fill.svg',
  IconPatchExclamation = 'twbs/patch-exclamation.svg',
  IconPatchMinusFill = 'twbs/patch-minus-fill.svg',
  IconPatchMinus = 'twbs/patch-minus.svg',
  IconPatchPlusFill = 'twbs/patch-plus-fill.svg',
  IconPatchPlus = 'twbs/patch-plus.svg',
  IconPatchQuestionFill = 'twbs/patch-question-fill.svg',
  IconPatchQuestion = 'twbs/patch-question.svg',
  IconPeaceFill = 'twbs/peace-fill.svg',
  IconPeace = 'twbs/peace.svg',
  IconPencilFill = 'twbs/pencil-fill.svg',
  IconPencilSquare = 'twbs/pencil-square.svg',
  IconPencil = 'twbs/pencil.svg',
  IconPentagonFill = 'twbs/pentagon-fill.svg',
  IconPentagonHalf = 'twbs/pentagon-half.svg',
  IconPentagon = 'twbs/pentagon.svg',
  IconPeopleFill = 'twbs/people-fill.svg',
  IconPeople = 'twbs/people.svg',
  IconPersonBadgeFill = 'twbs/person-badge-fill.svg',
  IconPersonBadge = 'twbs/person-badge.svg',
  IconPersonBoundingBox = 'twbs/person-bounding-box.svg',
  IconPersonCheckFill = 'twbs/person-check-fill.svg',
  IconPersonCheck = 'twbs/person-check.svg',
  IconPersonCircle = 'twbs/person-circle.svg',
  IconPersonDashFill = 'twbs/person-dash-fill.svg',
  IconPersonDash = 'twbs/person-dash.svg',
  IconPersonFill = 'twbs/person-fill.svg',
  IconPersonLinesFill = 'twbs/person-lines-fill.svg',
  IconPersonPlusFill = 'twbs/person-plus-fill.svg',
  IconPersonPlus = 'twbs/person-plus.svg',
  IconPersonSquare = 'twbs/person-square.svg',
  IconPersonXFill = 'twbs/person-x-fill.svg',
  IconPersonX = 'twbs/person-x.svg',
  IconPerson = 'twbs/person.svg',
  IconPiggyBankFill = 'twbs/piggy-bank-fill.svg',
  IconPiggyBank = 'twbs/piggy-bank.svg',
  IconPinAngleFill = 'twbs/pin-angle-fill.svg',
  IconPinAngle = 'twbs/pin-angle.svg',
  IconPinFill = 'twbs/pin-fill.svg',
  IconPinMapFill = 'twbs/pin-map-fill.svg',
  IconPinMap = 'twbs/pin-map.svg',
  IconPin = 'twbs/pin.svg',
  IconPlugFill = 'twbs/plug-fill.svg',
  IconPlug = 'twbs/plug.svg',
  IconPlusCircleDotted = 'twbs/plus-circle-dotted.svg',
  IconPlusCircleFill = 'twbs/plus-circle-fill.svg',
  IconPlusCircle = 'twbs/plus-circle.svg',
  IconPlusLg = 'twbs/plus-lg.svg',
  IconPlusSquareDotted = 'twbs/plus-square-dotted.svg',
  IconPlusSquareFill = 'twbs/plus-square-fill.svg',
  IconPlusSquare = 'twbs/plus-square.svg',
  IconPlus = 'twbs/plus.svg',
  IconQuestionCircleFill = 'twbs/question-circle-fill.svg',
  IconQuestionCircle = 'twbs/question-circle.svg',
  IconQuestionDiamondFill = 'twbs/question-diamond-fill.svg',
  IconQuestionDiamond = 'twbs/question-diamond.svg',
  IconQuestionLg = 'twbs/question-lg.svg',
  IconQuestionOctagonFill = 'twbs/question-octagon-fill.svg',
  IconQuestionOctagon = 'twbs/question-octagon.svg',
  IconQuestionSquareFill = 'twbs/question-square-fill.svg',
  IconQuestionSquare = 'twbs/question-square.svg',
  IconQuestion = 'twbs/question.svg',
  IconRainbow = 'twbs/rainbow.svg',
  IconShieldCheck = 'twbs/shield-check.svg',
  IconShieldExclamation = 'twbs/shield-exclamation.svg',
  IconShieldFillCheck = 'twbs/shield-fill-check.svg',
  IconShieldFillExclamation = 'twbs/shield-fill-exclamation.svg',
  IconShieldFillMinus = 'twbs/shield-fill-minus.svg',
  IconShieldFillPlus = 'twbs/shield-fill-plus.svg',
  IconShieldFillX = 'twbs/shield-fill-x.svg',
  IconShieldFill = 'twbs/shield-fill.svg',
  IconShieldLockFill = 'twbs/shield-lock-fill.svg',
  IconShieldLock = 'twbs/shield-lock.svg',
  IconShieldMinus = 'twbs/shield-minus.svg',
  IconShieldPlus = 'twbs/shield-plus.svg',
  IconShieldShaded = 'twbs/shield-shaded.svg',
  IconShieldSlashFill = 'twbs/shield-slash-fill.svg',
  IconShieldSlash = 'twbs/shield-slash.svg',
  IconShieldX = 'twbs/shield-x.svg',
  IconShield = 'twbs/shield.svg',
  IconSignpost2Fill = 'twbs/signpost-2-fill.svg',
  IconSignpost2 = 'twbs/signpost-2.svg',
  IconSignpostFill = 'twbs/signpost-fill.svg',
  IconSignpostSplitFill = 'twbs/signpost-split-fill.svg',
  IconSignpostSplit = 'twbs/signpost-split.svg',
  IconSignpost = 'twbs/signpost.svg',
  IconSnow = 'twbs/snow.svg',
  IconSnow2 = 'twbs/snow2.svg',
  IconSnow3 = 'twbs/snow3.svg',
  IconSuitClubFill = 'twbs/suit-club-fill.svg',
  IconSuitClub = 'twbs/suit-club.svg',
  IconSuitDiamondFill = 'twbs/suit-diamond-fill.svg',
  IconSuitDiamond = 'twbs/suit-diamond.svg',
  IconSuitHeartFill = 'twbs/suit-heart-fill.svg',
  IconSuitHeart = 'twbs/suit-heart.svg',
  IconSuitSpadeFill = 'twbs/suit-spade-fill.svg',
  IconSuitSpade = 'twbs/suit-spade.svg',
  IconSunFill = 'twbs/sun-fill.svg',
  IconSun = 'twbs/sun.svg',
  IconSunglasses = 'twbs/sunglasses.svg',
  IconSunriseFill = 'twbs/sunrise-fill.svg',
  IconSunrise = 'twbs/sunrise.svg',
  IconSunsetFill = 'twbs/sunset-fill.svg',
  IconSunset = 'twbs/sunset.svg',
  IconTagFill = 'twbs/tag-fill.svg',
  IconTag = 'twbs/tag.svg',
  IconTagsFill = 'twbs/tags-fill.svg',
  IconTags = 'twbs/tags.svg',
  IconTelephoneFill = 'twbs/telephone-fill.svg',
  IconTelephoneForwardFill = 'twbs/telephone-forward-fill.svg',
  IconTelephoneForward = 'twbs/telephone-forward.svg',
  IconTelephoneInboundFill = 'twbs/telephone-inbound-fill.svg',
  IconTelephoneInbound = 'twbs/telephone-inbound.svg',
  IconTelephoneMinusFill = 'twbs/telephone-minus-fill.svg',
  IconTelephoneMinus = 'twbs/telephone-minus.svg',
  IconTelephoneOutboundFill = 'twbs/telephone-outbound-fill.svg',
  IconTelephoneOutbound = 'twbs/telephone-outbound.svg',
  IconTelephonePlusFill = 'twbs/telephone-plus-fill.svg',
  IconTelephonePlus = 'twbs/telephone-plus.svg',
  IconTelephoneXFill = 'twbs/telephone-x-fill.svg',
  IconTelephoneX = 'twbs/telephone-x.svg',
  IconTelephone = 'twbs/telephone.svg',
  IconThermometerHalf = 'twbs/thermometer-half.svg',
  IconThermometerHigh = 'twbs/thermometer-high.svg',
  IconThermometerLow = 'twbs/thermometer-low.svg',
  IconThermometerSnow = 'twbs/thermometer-snow.svg',
  IconThermometerSun = 'twbs/thermometer-sun.svg',
  IconThermometer = 'twbs/thermometer.svg',
  IconTrashFill = 'twbs/trash-fill.svg',
  IconTrash = 'twbs/trash.svg',
  IconTrash2Fill = 'twbs/trash2-fill.svg',
  IconTrash2 = 'twbs/trash2.svg',
  IconTreeFill = 'twbs/tree-fill.svg',
  IconTree = 'twbs/tree.svg',
  IconTrophyFill = 'twbs/trophy-fill.svg',
  IconTrophy = 'twbs/trophy.svg',
  IconTropicalStorm = 'twbs/tropical-storm.svg',
  IconTruckFlatbed = 'twbs/truck-flatbed.svg',
  IconTruck = 'twbs/truck.svg',
  IconTsunami = 'twbs/tsunami.svg',
  IconUmbrellaFill = 'twbs/umbrella-fill.svg',
  IconUmbrella = 'twbs/umbrella.svg',
  IconUnion = 'twbs/union.svg',
  IconUnlockFill = 'twbs/unlock-fill.svg',
  IconUnlock = 'twbs/unlock.svg',
  IconWatch = 'twbs/watch.svg',
  IconWater = 'twbs/water.svg',
  IconWifi1 = 'twbs/wifi-1.svg',
  IconWifi2 = 'twbs/wifi-2.svg',
  IconWifiOff = 'twbs/wifi-off.svg',
  IconWifi = 'twbs/wifi.svg',
  IconWind = 'twbs/wind.svg',
  IconXCircleFill = 'twbs/x-circle-fill.svg',
  IconXCircle = 'twbs/x-circle.svg',
  IconXDiamondFill = 'twbs/x-diamond-fill.svg',
  IconXDiamond = 'twbs/x-diamond.svg',
  IconXLg = 'twbs/x-lg.svg',
  IconXOctagonFill = 'twbs/x-octagon-fill.svg',
  IconXOctagon = 'twbs/x-octagon.svg',
  IconXSquareFill = 'twbs/x-square-fill.svg',
  IconXSquare = 'twbs/x-square.svg',
  IconX = 'twbs/x.svg',
  IconZoomIn = 'twbs/zoom-in.svg',
  IconZoomOut = 'twbs/zoom-out.svg',
  IconIso7001RedCross = 'iso7001/ISO-7001---Red-Cross.svg',
  IconIso7001RedSlash = 'iso7001/ISO-7001---Red-Slash.svg',
  IconIso7001PiBp001 = 'iso7001/ISO-7001-PI-BP-001.svg',
  IconIso7001PiBp002 = 'iso7001/ISO-7001-PI-BP-002.svg',
  IconIso7001PiBp003 = 'iso7001/ISO-7001-PI-BP-003.svg',
  IconIso7001PiBp004 = 'iso7001/ISO-7001-PI-BP-004.svg',
  IconIso7001PiBp005 = 'iso7001/ISO-7001-PI-BP-005.svg',
  IconIso7001PiBp006 = 'iso7001/ISO-7001-PI-BP-006.svg',
  IconIso7001PiBp007 = 'iso7001/ISO-7001-PI-BP-007.svg',
  IconIso7001PiBp008 = 'iso7001/ISO-7001-PI-BP-008.svg',
  IconIso7001PiBp009 = 'iso7001/ISO-7001-PI-BP-009.svg',
  IconIso7001PiBp010 = 'iso7001/ISO-7001-PI-BP-010.svg',
  IconIso7001PiBp011 = 'iso7001/ISO-7001-PI-BP-011.svg',
  IconIso7001PiBp012 = 'iso7001/ISO-7001-PI-BP-012.svg',
  IconIso7001PiBp013 = 'iso7001/ISO-7001-PI-BP-013.svg',
  IconIso7001PiBp014 = 'iso7001/ISO-7001-PI-BP-014.svg',
  IconIso7001PiBp015 = 'iso7001/ISO-7001-PI-BP-015.svg',
  IconIso7001PiBp016 = 'iso7001/ISO-7001-PI-BP-016.svg',
  IconIso7001PiBp017 = 'iso7001/ISO-7001-PI-BP-017.svg',
  IconIso7001PiBp018 = 'iso7001/ISO-7001-PI-BP-018.svg',
  IconIso7001PiBp019 = 'iso7001/ISO-7001-PI-BP-019.svg',
  IconIso7001PiCf001 = 'iso7001/ISO-7001-PI-CF-001.svg',
  IconIso7001PiCf002 = 'iso7001/ISO-7001-PI-CF-002.svg',
  IconIso7001PiCf003 = 'iso7001/ISO-7001-PI-CF-003.svg',
  IconIso7001PiCf004 = 'iso7001/ISO-7001-PI-CF-004.svg',
  IconIso7001PiCf005 = 'iso7001/ISO-7001-PI-CF-005.svg',
  IconIso7001PiCf006 = 'iso7001/ISO-7001-PI-CF-006.svg',
  IconIso7001PiCf007 = 'iso7001/ISO-7001-PI-CF-007.svg',
  IconIso7001PiCf008 = 'iso7001/ISO-7001-PI-CF-008.svg',
  IconIso7001PiCf009 = 'iso7001/ISO-7001-PI-CF-009.svg',
  IconIso7001PiCf010 = 'iso7001/ISO-7001-PI-CF-010.svg',
  IconIso7001PiCf011 = 'iso7001/ISO-7001-PI-CF-011.svg',
  IconIso7001PiCf012 = 'iso7001/ISO-7001-PI-CF-012.svg',
  IconIso7001PiCf014 = 'iso7001/ISO-7001-PI-CF-014.svg',
  IconIso7001PiCf015 = 'iso7001/ISO-7001-PI-CF-015.svg',
  IconIso7001PiCf016 = 'iso7001/ISO-7001-PI-CF-016.svg',
  IconIso7001PiCf017 = 'iso7001/ISO-7001-PI-CF-017.svg',
  IconIso7001PiCf018 = 'iso7001/ISO-7001-PI-CF-018.svg',
  IconIso7001PiCf019 = 'iso7001/ISO-7001-PI-CF-019.svg',
  IconIso7001PiCf020 = 'iso7001/ISO-7001-PI-CF-020.svg',
  IconIso7001PiCf021 = 'iso7001/ISO-7001-PI-CF-021.svg',
  IconIso7001PiCf022 = 'iso7001/ISO-7001-PI-CF-022.svg',
  IconIso7001PiPf001 = 'iso7001/ISO-7001-PI-PF-001.svg',
  IconIso7001PiPf002 = 'iso7001/ISO-7001-PI-PF-002.svg',
  IconIso7001PiPf003 = 'iso7001/ISO-7001-PI-PF-003.svg',
  IconIso7001PiPf004 = 'iso7001/ISO-7001-PI-PF-004.svg',
  IconIso7001PiPf005 = 'iso7001/ISO-7001-PI-PF-005.svg',
  IconIso7001PiPf006 = 'iso7001/ISO-7001-PI-PF-006.svg',
  IconIso7001PiPf007 = 'iso7001/ISO-7001-PI-PF-007.svg',
  IconIso7001PiPf008 = 'iso7001/ISO-7001-PI-PF-008.svg',
  IconIso7001PiPf009 = 'iso7001/ISO-7001-PI-PF-009.svg',
  IconIso7001PiPf010 = 'iso7001/ISO-7001-PI-PF-010.svg',
  IconIso7001PiPf011 = 'iso7001/ISO-7001-PI-PF-011.svg',
  IconIso7001PiPf012 = 'iso7001/ISO-7001-PI-PF-012.svg',
  IconIso7001PiPf013 = 'iso7001/ISO-7001-PI-PF-013.svg',
  IconIso7001PiPf014 = 'iso7001/ISO-7001-PI-PF-014.svg',
  IconIso7001PiPf015 = 'iso7001/ISO-7001-PI-PF-015.svg',
  IconIso7001PiPf016 = 'iso7001/ISO-7001-PI-PF-016.svg',
  IconIso7001PiPf017 = 'iso7001/ISO-7001-PI-PF-017.svg',
  IconIso7001PiPf018 = 'iso7001/ISO-7001-PI-PF-018.svg',
  IconIso7001PiPf019 = 'iso7001/ISO-7001-PI-PF-019.svg',
  IconIso7001PiPf020 = 'iso7001/ISO-7001-PI-PF-020.svg',
  IconIso7001PiPf021 = 'iso7001/ISO-7001-PI-PF-021.svg',
  IconIso7001PiPf022 = 'iso7001/ISO-7001-PI-PF-022.svg',
  IconIso7001PiPf023 = 'iso7001/ISO-7001-PI-PF-023.svg',
  IconIso7001PiPf024 = 'iso7001/ISO-7001-PI-PF-024.svg',
  IconIso7001PiPf025 = 'iso7001/ISO-7001-PI-PF-025.svg',
  IconIso7001PiPf026 = 'iso7001/ISO-7001-PI-PF-026.svg',
  IconIso7001PiPf027 = 'iso7001/ISO-7001-PI-PF-027.svg',
  IconIso7001PiPf028 = 'iso7001/ISO-7001-PI-PF-028.svg',
  IconIso7001PiPf029 = 'iso7001/ISO-7001-PI-PF-029.svg',
  IconIso7001PiPf030 = 'iso7001/ISO-7001-PI-PF-030.svg',
  IconIso7001PiPf031 = 'iso7001/ISO-7001-PI-PF-031.svg',
  IconIso7001PiPf032 = 'iso7001/ISO-7001-PI-PF-032.svg',
  IconIso7001PiPf033 = 'iso7001/ISO-7001-PI-PF-033.svg',
  IconIso7001PiPf034 = 'iso7001/ISO-7001-PI-PF-034.svg',
  IconIso7001PiPf036 = 'iso7001/ISO-7001-PI-PF-036.svg',
  IconIso7001PiPf037 = 'iso7001/ISO-7001-PI-PF-037.svg',
  IconIso7001PiPf043 = 'iso7001/ISO-7001-PI-PF-043.svg',
  IconIso7001PiPf044 = 'iso7001/ISO-7001-PI-PF-044.svg',
  IconIso7001PiPf045 = 'iso7001/ISO-7001-PI-PF-045.svg',
  IconIso7001PiPf046 = 'iso7001/ISO-7001-PI-PF-046.svg',
  IconIso7001PiPf047 = 'iso7001/ISO-7001-PI-PF-047.svg',
  IconIso7001PiPf048 = 'iso7001/ISO-7001-PI-PF-048.svg',
  IconIso7001PiPf049 = 'iso7001/ISO-7001-PI-PF-049.svg',
  IconIso7001PiPf050 = 'iso7001/ISO-7001-PI-PF-050.svg',
  IconIso7001PiPf051 = 'iso7001/ISO-7001-PI-PF-051.svg',
  IconIso7001PiPf054 = 'iso7001/ISO-7001-PI-PF-054.svg',
  IconIso7001PiPf055 = 'iso7001/ISO-7001-PI-PF-055.svg',
  IconIso7001PiPf056 = 'iso7001/ISO-7001-PI-PF-056.svg',
  IconIso7001PiPf057 = 'iso7001/ISO-7001-PI-PF-057.svg',
  IconIso7001PiPf058 = 'iso7001/ISO-7001-PI-PF-058.svg',
  IconIso7001PiPf059 = 'iso7001/ISO-7001-PI-PF-059.svg',
  IconIso7001PiPf062 = 'iso7001/ISO-7001-PI-PF-062.svg',
  IconIso7001PiPf063 = 'iso7001/ISO-7001-PI-PF-063.svg',
  IconIso7001PiPf064 = 'iso7001/ISO-7001-PI-PF-064.svg',
  IconIso7001PiPf065 = 'iso7001/ISO-7001-PI-PF-065.svg',
  IconIso7001PiPf066 = 'iso7001/ISO-7001-PI-PF-066.svg',
  IconIso7001PiPf067 = 'iso7001/ISO-7001-PI-PF-067.svg',
  IconIso7001PiPf068 = 'iso7001/ISO-7001-PI-PF-068.svg',
  IconIso7001PiPf069 = 'iso7001/ISO-7001-PI-PF-069.svg',
  IconIso7001PiPf070 = 'iso7001/ISO-7001-PI-PF-070.svg',
  IconIso7001PiPf072 = 'iso7001/ISO-7001-PI-PF-072.svg',
  IconIso7001PiPf073 = 'iso7001/ISO-7001-PI-PF-073.svg',
  IconIso7001PiPf074 = 'iso7001/ISO-7001-PI-PF-074.svg',
  IconIso7001PiPf075 = 'iso7001/ISO-7001-PI-PF-075.svg',
  IconIso7001PiPf076 = 'iso7001/ISO-7001-PI-PF-076.svg',
  IconIso7001PiPf077 = 'iso7001/ISO-7001-PI-PF-077.svg',
  IconIso7001PiPf078 = 'iso7001/ISO-7001-PI-PF-078.svg',
  IconIso7001PiPf079 = 'iso7001/ISO-7001-PI-PF-079.svg',
  IconIso7001PiPf080 = 'iso7001/ISO-7001-PI-PF-080.svg',
  IconIso7001PiPf081 = 'iso7001/ISO-7001-PI-PF-081.svg',
  IconIso7001PiPf082 = 'iso7001/ISO-7001-PI-PF-082.svg',
  IconIso7001PiSa001 = 'iso7001/ISO-7001-PI-SA-001.svg',
  IconIso7001PiSa002 = 'iso7001/ISO-7001-PI-SA-002.svg',
  IconIso7001PiSa003 = 'iso7001/ISO-7001-PI-SA-003.svg',
  IconIso7001PiSa004 = 'iso7001/ISO-7001-PI-SA-004.svg',
  IconIso7001PiTc001 = 'iso7001/ISO-7001-PI-TC-001.svg',
  IconIso7001PiTc002 = 'iso7001/ISO-7001-PI-TC-002.svg',
  IconIso7001PiTc003 = 'iso7001/ISO-7001-PI-TC-003.svg',
  IconIso7001PiTc004 = 'iso7001/ISO-7001-PI-TC-004.svg',
  IconIso7001PiTc005 = 'iso7001/ISO-7001-PI-TC-005.svg',
  IconIso7001PiTc006 = 'iso7001/ISO-7001-PI-TC-006.svg',
  IconIso7001PiTc007 = 'iso7001/ISO-7001-PI-TC-007.svg',
  IconIso7001PiTc008 = 'iso7001/ISO-7001-PI-TC-008.svg',
  IconIso7001PiTc009 = 'iso7001/ISO-7001-PI-TC-009.svg',
  IconIso7001PiTc010 = 'iso7001/ISO-7001-PI-TC-010.svg',
  IconIso7001PiTc011 = 'iso7001/ISO-7001-PI-TC-011.svg',
  IconIso7001PiTc012 = 'iso7001/ISO-7001-PI-TC-012.svg',
  IconIso7001PiTc013 = 'iso7001/ISO-7001-PI-TC-013.svg',
  IconIso7001PiTc014 = 'iso7001/ISO-7001-PI-TC-014.svg',
  IconIso7001PiTc015 = 'iso7001/ISO-7001-PI-TC-015.svg',
  IconIso7001PiTc016 = 'iso7001/ISO-7001-PI-TC-016.svg',
  IconIso7001PiTc017 = 'iso7001/ISO-7001-PI-TC-017.svg',
  IconIso7001PiTc018 = 'iso7001/ISO-7001-PI-TC-018.svg',
  IconIso7001PiTf001 = 'iso7001/ISO-7001-PI-TF-001.svg',
  IconIso7001PiTf002 = 'iso7001/ISO-7001-PI-TF-002.svg',
  IconIso7001PiTf003 = 'iso7001/ISO-7001-PI-TF-003.svg',
  IconIso7001PiTf004 = 'iso7001/ISO-7001-PI-TF-004.svg',
  IconIso7001PiTf005 = 'iso7001/ISO-7001-PI-TF-005.svg',
  IconIso7001PiTf006 = 'iso7001/ISO-7001-PI-TF-006.svg',
  IconIso7001PiTf007 = 'iso7001/ISO-7001-PI-TF-007.svg',
  IconIso7001PiTf008 = 'iso7001/ISO-7001-PI-TF-008.svg',
  IconIso7001PiTf009 = 'iso7001/ISO-7001-PI-TF-009.svg',
  IconIso7001PiTf010 = 'iso7001/ISO-7001-PI-TF-010.svg',
  IconIso7001PiTf011 = 'iso7001/ISO-7001-PI-TF-011.svg',
  IconIso7001PiTf012 = 'iso7001/ISO-7001-PI-TF-012.svg',
  IconIso7001PiTf013 = 'iso7001/ISO-7001-PI-TF-013.svg',
  IconIso7001PiTf014 = 'iso7001/ISO-7001-PI-TF-014.svg',
  IconIso7001PiTf015 = 'iso7001/ISO-7001-PI-TF-015.svg',
  IconIso7001PiTf016 = 'iso7001/ISO-7001-PI-TF-016.svg',
  IconIso7001PiTf017 = 'iso7001/ISO-7001-PI-TF-017.svg',
  IconIso7001PiTf018 = 'iso7001/ISO-7001-PI-TF-018.svg',
  IconIso7001PiTf019 = 'iso7001/ISO-7001-PI-TF-019.svg',
  IconIso7001PiTf020 = 'iso7001/ISO-7001-PI-TF-020.svg',
  IconIso7001PiTf021 = 'iso7001/ISO-7001-PI-TF-021.svg',
  IconIso7001PiTf022 = 'iso7001/ISO-7001-PI-TF-022.svg',
  IconIso7001PiTf023 = 'iso7001/ISO-7001-PI-TF-023.svg',
  IconIso7001PiTf024 = 'iso7001/ISO-7001-PI-TF-024.svg',
  IconIso7001PiTf025 = 'iso7001/ISO-7001-PI-TF-025.svg',
  IconIso7001PiTf026 = 'iso7001/ISO-7001-PI-TF-026.svg',
  IconIso7001PiTf027 = 'iso7001/ISO-7001-PI-TF-027.svg',
  IconIso7001PiTf028 = 'iso7001/ISO-7001-PI-TF-028.svg',
  IconIso7001PiTf029 = 'iso7001/ISO-7001-PI-TF-029.svg',
  IconIso7001PiTf030 = 'iso7001/ISO-7001-PI-TF-030.svg',
  IconIso7001PiTf031 = 'iso7001/ISO-7001-PI-TF-031.svg',
  IconIso7001PiTf032 = 'iso7001/ISO-7001-PI-TF-032.svg',
  IconIso7001PiTf033 = 'iso7001/ISO-7001-PI-TF-033.svg',
  IconIso7001PiTf034 = 'iso7001/ISO-7001-PI-TF-034.svg',
  IconIso7001PiTf035 = 'iso7001/ISO-7001-PI-TF-035.svg',
  IconIso7001PiTf036 = 'iso7001/ISO-7001-PI-TF-036.svg',
  IconIso7001PiTf037 = 'iso7001/ISO-7001-PI-TF-037.svg',
  IconIso7001PiTf038 = 'iso7001/ISO-7001-PI-TF-038.svg',
  IconIso7001PiTf039 = 'iso7001/ISO-7001-PI-TF-039.svg',
  IconIso7001PiTf040 = 'iso7001/ISO-7001-PI-TF-040.svg',
  IconIso7001PiTf041 = 'iso7001/ISO-7001-PI-TF-041.svg',
  IconIso7001PiTf042 = 'iso7001/ISO-7001-PI-TF-042.svg',
  IconIso7001PiTf043 = 'iso7001/ISO-7001-PI-TF-043.svg',
  IconIso7001PiTf044 = 'iso7001/ISO-7001-PI-TF-044.svg',
  IconDin48442DP017 = 'iso7010/DIN-4844-2-D-P017.svg',
  IconIso7010E002New = 'iso7010/ISO-7010-E002-new.svg',
  IconIso7010E003FirstAidSign = 'iso7010/ISO-7010-E003---First-aid-sign.svg',
  IconIso7010E004 = 'iso7010/ISO-7010-E004.svg',
  IconIso7010E007 = 'iso7010/ISO-7010-E007.svg',
  IconIso7010E008 = 'iso7010/ISO-7010-E008.svg',
  IconIso7010E009 = 'iso7010/ISO-7010-E009.svg',
  IconIso7010E010 = 'iso7010/ISO-7010-E010.svg',
  IconIso7010E011 = 'iso7010/ISO-7010-E011.svg',
  IconIso7010E012 = 'iso7010/ISO-7010-E012.svg',
  IconIso7010E013 = 'iso7010/ISO-7010-E013.svg',
  IconIso7010E016 = 'iso7010/ISO-7010-E016.svg',
  IconIso7010E017 = 'iso7010/ISO-7010-E017.svg',
  IconIso7010E018 = 'iso7010/ISO-7010-E018.svg',
  IconIso7010E019 = 'iso7010/ISO-7010-E019.svg',
  IconIso7010F001 = 'iso7010/ISO-7010-F001.svg',
  IconIso7010F002 = 'iso7010/ISO-7010-F002.svg',
  IconIso7010F003 = 'iso7010/ISO-7010-F003.svg',
  IconIso7010F004 = 'iso7010/ISO-7010-F004.svg',
  IconIso7010F005 = 'iso7010/ISO-7010-F005.svg',
  IconIso7010F006 = 'iso7010/ISO-7010-F006.svg',
  IconIso7010F007 = 'iso7010/ISO-7010-F007.svg',
  IconIso7010M001 = 'iso7010/ISO-7010-M001.svg',
  IconIso7010M002 = 'iso7010/ISO-7010-M002.svg',
  IconIso7010M003 = 'iso7010/ISO-7010-M003.svg',
  IconIso7010M004 = 'iso7010/ISO-7010-M004.svg',
  IconIso7010M005 = 'iso7010/ISO-7010-M005.svg',
  IconIso7010M006 = 'iso7010/ISO-7010-M006.svg',
  IconIso7010M007 = 'iso7010/ISO-7010-M007.svg',
  IconIso7010M008 = 'iso7010/ISO-7010-M008.svg',
  IconIso7010M009 = 'iso7010/ISO-7010-M009.svg',
  IconIso7010M010 = 'iso7010/ISO-7010-M010.svg',
  IconIso7010M011 = 'iso7010/ISO-7010-M011.svg',
  IconIso7010M012 = 'iso7010/ISO-7010-M012.svg',
  IconIso7010M013 = 'iso7010/ISO-7010-M013.svg',
  IconIso7010M014 = 'iso7010/ISO-7010-M014.svg',
  IconIso7010M015 = 'iso7010/ISO-7010-M015.svg',
  IconIso7010M016 = 'iso7010/ISO-7010-M016.svg',
  IconIso7010M017 = 'iso7010/ISO-7010-M017.svg',
  IconIso7010M018 = 'iso7010/ISO-7010-M018.svg',
  IconIso7010M019 = 'iso7010/ISO-7010-M019.svg',
  IconIso7010M020 = 'iso7010/ISO-7010-M020.svg',
  IconIso7010M021 = 'iso7010/ISO-7010-M021.svg',
  IconIso7010M022 = 'iso7010/ISO-7010-M022.svg',
  IconIso7010M023 = 'iso7010/ISO-7010-M023.svg',
  IconIso7010M024 = 'iso7010/ISO-7010-M024.svg',
  IconIso7010M025 = 'iso7010/ISO-7010-M025.svg',
  IconIso7010M026 = 'iso7010/ISO-7010-M026.svg',
  IconIso7010M027MandatoryInstallOrCheckGuard = 'iso7010/ISO-7010-M027-mandatory-install-or-check-guard.svg',
  IconIso7010M028MandatoryInstallLocksAndKeepLocked = 'iso7010/ISO-7010-M028-mandatory-install-locks-and-keep-locked.svg',
  IconIso7010M029MandatorySoundYourHorn = 'iso7010/ISO-7010-M029-mandatory-sound-your-horn.svg',
  IconIso7010M030MandatoryPlaceTrashInTheBin = 'iso7010/ISO-7010-M030-mandatory-place-trash-in-the-bin.svg',
  IconIso7010M031MandatoryUseGuardToProtectFromInjuryFromTheTableSaw = 'iso7010/ISO-7010-M031-mandatory-use-guard-to-protect-from-injury-from-the-table-saw.svg',
  IconIso7010M032MandatoryUseFootwearWithAntiStaticOrAntiSparkFeatures = 'iso7010/ISO-7010-M032-mandatory-use-footwear-with-anti-static-or-anti-spark-features.svg',
  IconIso7010M033MandatoryLowerSafetyRestrainingBarOnSkiChairlift = 'iso7010/ISO-7010-M033-mandatory-lower-safety-restraining-bar-on-ski-chairlift.svg',
  IconIso7010M034MandatoryRaiseSafetyRestrainingBarOnSkiChairlift = 'iso7010/ISO-7010-M034-mandatory-raise-safety-restraining-bar-on-ski-chairlift.svg',
  IconIso7010M035MandatoryExitTowpathAfterFalling = 'iso7010/ISO-7010-M035-mandatory-exit-towpath-after-falling.svg',
  IconIso7010M036MandatoryKeepSkiTipsUp = 'iso7010/ISO-7010-M036-mandatory-keep-ski-tips-up.svg',
  IconIso7010M037MandatoryCloseAndSecureTheHatchLifeboatLaunchSequence = 'iso7010/ISO-7010-M037-mandatory-close-and-secure-the-hatch-lifeboat-launch-sequence.svg',
  IconIso7010M038MandatoryStartTheMotorLifeboatLaunchSequence = 'iso7010/ISO-7010-M038-mandatory-start-the-motor-lifeboat-launch-sequence.svg',
  IconIso7010M039MandatoryLowerTheLifeboatToTheWaterLifeboatLaunchSequence = 'iso7010/ISO-7010-M039-mandatory-lower-the-lifeboat-to-the-water-lifeboat-launch-sequence.svg',
  IconIso7010M040MandatoryLowerTheLifeboatToTheWaterLifeboatLaunchSequence = 'iso7010/ISO-7010-M040-mandatory-lower-the-lifeboat-to-the-water-lifeboat-launch-sequence.svg',
  IconIso7010M041MandatoryLowerTheRescueBoatToTheWaterLifeboatLaunchSequence = 'iso7010/ISO-7010-M041-mandatory-lower-the-rescue-boat-to-the-water-lifeboat-launch-sequence.svg',
  IconIso7010M042MandatoryReleaseTheHooksHoldingTheLifeboatLifeboatLaunchSequence = 'iso7010/ISO-7010-M042-mandatory-release-the-hooks-holding-the-lifeboat-lifeboat-launch-sequence.svg',
  IconIso7010M043MandatoryStartTheWaterSprayForTheLifeboatLifeboatLaunchSequence = 'iso7010/ISO-7010-M043-mandatory-start-the-water-spray-for-the-lifeboat-lifeboat-launch-sequence.svg',
  IconIso7010M044MandatoryStartTheAirSupplyForTheLifeboatLifeboatLaunchSequence = 'iso7010/ISO-7010-M044-mandatory-start-the-air-supply-for-the-lifeboat-lifeboat-launch-sequence.svg',
  IconIso7010M045MandatoryReleaseTheRopesGripesLifeboatLaunchSequence = 'iso7010/ISO-7010-M045-mandatory-release-the-ropes-gripes-lifeboat-launch-sequence.svg',
  IconIso7010M046MandatorySecureGasCylinders = 'iso7010/ISO-7010-M046-mandatory-secure-gas-cylinders.svg',
  IconIso7010M047MandatoryUseBreathingEquipmentScba = 'iso7010/ISO-7010-M047-mandatory-use-breathing-equipment-SCBA.svg',
  IconIso7010M048GasdetektorBenutzen = 'iso7010/ISO-7010-M048-Gasdetektor-benutzen.svg',
  IconIso7010M049MandatoryUsePersonalProtectiveEquipmentForSport = 'iso7010/ISO-7010-M049-mandatory-use-personal-protective-equipment-for-sport.svg',
  IconIso7010M050MandatoryExitSledTobogganToTheLeft = 'iso7010/ISO-7010-M050-mandatory-exit-sled-toboggan-to-the-left.svg',
  IconIso7010M051MandatoryExitSledTobogganToTheRight = 'iso7010/ISO-7010-M051-mandatory-exit-sled-toboggan-to-the-right.svg',
  IconIso7010M052MandatoryKeepASafeDistanceBetweenSledsToboggans = 'iso7010/ISO-7010-M052-mandatory-keep-a-safe-distance-between-sleds-toboggans.svg',
  IconIso7010M053MandatoryWearPersonalFloatationDevicePfdLifejacket = 'iso7010/ISO-7010-M053-mandatory-wear-personal-floatation-device-PFD-lifejacket.svg',
  IconIso7010M054SuperviseChildrenDuringAquaticActivities = 'iso7010/ISO-7010-M054-supervise-children-during-aquatic-activities.svg',
  IconIso7010M055 = 'iso7010/ISO-7010-M055.svg',
  IconIso7010P001 = 'iso7010/ISO-7010-P001.svg',
  IconIso7010P002 = 'iso7010/ISO-7010-P002.svg',
  IconIso7010P003 = 'iso7010/ISO-7010-P003.svg',
  IconIso7010P004 = 'iso7010/ISO-7010-P004.svg',
  IconIso7010P005 = 'iso7010/ISO-7010-P005.svg',
  IconIso7010P006 = 'iso7010/ISO-7010-P006.svg',
  IconIso7010P007 = 'iso7010/ISO-7010-P007.svg',
  IconIso7010P008 = 'iso7010/ISO-7010-P008.svg',
  IconIso7010P010 = 'iso7010/ISO-7010-P010.svg',
  IconIso7010P011 = 'iso7010/ISO-7010-P011.svg',
  IconIso7010P012 = 'iso7010/ISO-7010-P012.svg',
  IconIso7010P013 = 'iso7010/ISO-7010-P013.svg',
  IconIso7010P014 = 'iso7010/ISO-7010-P014.svg',
  IconIso7010P015 = 'iso7010/ISO-7010-P015.svg',
  IconIso7010P017 = 'iso7010/ISO-7010-P017.svg',
  IconIso7010P018 = 'iso7010/ISO-7010-P018.svg',
  IconIso7010P019 = 'iso7010/ISO-7010-P019.svg',
  IconIso7010P020 = 'iso7010/ISO-7010-P020.svg',
  IconIso7010P021 = 'iso7010/ISO-7010-P021.svg',
  IconIso7010P022 = 'iso7010/ISO-7010-P022.svg',
  IconIso7010P023 = 'iso7010/ISO-7010-P023.svg',
  IconIso7010P024 = 'iso7010/ISO-7010-P024.svg',
  IconIso7010P025 = 'iso7010/ISO-7010-P025.svg',
  IconIso7010P026 = 'iso7010/ISO-7010-P026.svg',
  IconIso7010P027 = 'iso7010/ISO-7010-P027.svg',
  IconIso7010P028 = 'iso7010/ISO-7010-P028.svg',
  IconIso7010P029 = 'iso7010/ISO-7010-P029.svg',
  IconIso7010P030 = 'iso7010/ISO-7010-P030.svg',
  IconIso7010P031 = 'iso7010/ISO-7010-P031.svg',
  IconIso7010P032 = 'iso7010/ISO-7010-P032.svg',
  IconIso7010P033 = 'iso7010/ISO-7010-P033.svg',
  IconIso7010P034 = 'iso7010/ISO-7010-P034.svg',
  IconIso7010SafeConditionArrow1 = 'iso7010/ISO-7010-Safe-condition-arrow-1.svg',
  IconIso7010SafeConditionArrow2 = 'iso7010/ISO-7010-Safe-condition-arrow-2.svg',
  IconIso7010W001 = 'iso7010/ISO-7010-W001.svg',
  IconIso7010W002 = 'iso7010/ISO-7010-W002.svg',
  IconIso7010W003 = 'iso7010/ISO-7010-W003.svg',
  IconIso7010W004 = 'iso7010/ISO-7010-W004.svg',
  IconIso7010W005 = 'iso7010/ISO-7010-W005.svg',
  IconIso7010W006 = 'iso7010/ISO-7010-W006.svg',
  IconIso7010W007 = 'iso7010/ISO-7010-W007.svg',
  IconIso7010W008 = 'iso7010/ISO-7010-W008.svg',
  IconIso7010W009 = 'iso7010/ISO-7010-W009.svg',
  IconIso7010W010 = 'iso7010/ISO-7010-W010.svg',
  IconIso7010W011 = 'iso7010/ISO-7010-W011.svg',
  IconIso7010W012 = 'iso7010/ISO-7010-W012.svg',
  IconIso7010W013 = 'iso7010/ISO-7010-W013.svg',
  IconIso7010W014 = 'iso7010/ISO-7010-W014.svg',
  IconIso7010W015 = 'iso7010/ISO-7010-W015.svg',
  IconIso7010W016 = 'iso7010/ISO-7010-W016.svg',
  IconIso7010W017 = 'iso7010/ISO-7010-W017.svg',
  IconIso7010W018 = 'iso7010/ISO-7010-W018.svg',
  IconIso7010W019 = 'iso7010/ISO-7010-W019.svg',
  IconIso7010W020 = 'iso7010/ISO-7010-W020.svg',
  IconIso7010W021 = 'iso7010/ISO-7010-W021.svg',
  IconIso7010W022 = 'iso7010/ISO-7010-W022.svg',
  IconIso7010W023 = 'iso7010/ISO-7010-W023.svg',
  IconIso7010W024 = 'iso7010/ISO-7010-W024.svg',
  IconIso7010W025 = 'iso7010/ISO-7010-W025.svg',
  IconIso7010W026 = 'iso7010/ISO-7010-W026.svg',
  IconIso7010W027 = 'iso7010/ISO-7010-W027.svg',
  IconIso7010W028 = 'iso7010/ISO-7010-W028.svg',
  IconIso7010W029 = 'iso7010/ISO-7010-W029.svg',
  IconIso7010W031WarningHandInjuryFromWorkpieceMovingInPressBrakeMachinery = 'iso7010/ISO-7010-W031-warning-hand-injury-from-workpiece-moving-in-press-brake-machinery.svg',
  IconIso7010W035 = 'iso7010/ISO-7010-W035.svg',
  IconIso7010W039 = 'iso7010/ISO-7010-W039.svg',
  IconIso7010W040WarningRoofAvalanche = 'iso7010/ISO-7010-W040-warning-roof-avalanche.svg',
  IconIso7010W041WarningBreathingHazard = 'iso7010/ISO-7010-W041-warning-breathing-hazard.svg',
  IconIso7010W042WarningArcFlashHazard = 'iso7010/ISO-7010-W042-warning-arc-flash-hazard.svg',
  IconIso7010W043WarningThinIce = 'iso7010/ISO-7010-W043-warning-thin-ice.svg',
  IconIso7010W044WarningSlipway = 'iso7010/ISO-7010-W044-warning-slipway.svg',
  IconIso7010W045WarningWaterSkiingOrTowedWaterActivityArea = 'iso7010/ISO-7010-W045-warning-water-skiing-or-towed-water-activity-area.svg',
  IconIso7010W046WarningSurfing = 'iso7010/ISO-7010-W046-warning-surfing.svg',
  IconIso7010W047WarningDeepWater = 'iso7010/ISO-7010-W047-warning-deep-water.svg',
  IconIso7010W048WarningShallowWater = 'iso7010/ISO-7010-W048-warning-shallow-water.svg',
  IconIso7010W049WarningSubmergedObjects = 'iso7010/ISO-7010-W049-warning-submerged-objects.svg',
  IconIso7010W050WarningSuddenDropInSwimmingPool = 'iso7010/ISO-7010-W050-warning-sudden-drop-in-swimming-pool.svg',
  IconIso7010W051WarningNoGuardRailAndUnprotectedEdges = 'iso7010/ISO-7010-W051-warning-no-guard-rail-and-unprotected-edges.svg',
  IconIso7010W052WarningUnstableCliffEdge = 'iso7010/ISO-7010-W052-warning-unstable-cliff-edge.svg',
  IconIso7010W053WarningUnstableCliff = 'iso7010/ISO-7010-W053-warning-unstable-cliff.svg',
  IconIso7010W054WarningSharks = 'iso7010/ISO-7010-W054-warning-sharks.svg',
  IconIso7010W055WarningSewageEffluentOutfall = 'iso7010/ISO-7010-W055-warning-sewage-effluent-outfall.svg',
  IconIso7010W056WarningTsunamiHazardZone = 'iso7010/ISO-7010-W056-warning-tsunami-hazard-zone.svg',
  IconIso7010W057WarningStrongCurrents = 'iso7010/ISO-7010-W057-warning-strong-currents.svg',
  IconIso7010W058WarningBoatingArea = 'iso7010/ISO-7010-W058-warning-boating-area.svg',
  IconIso7010W059SandYachting = 'iso7010/ISO-7010-W059-sand-yachting.svg',
  IconIso7010W060WarningIncomingTides = 'iso7010/ISO-7010-W060-warning-incoming-tides.svg',
  IconIso7010W061WarningQuicksandMudDeepMudOrSilt = 'iso7010/ISO-7010-W061-warning-quicksand-mud-deep-mud-or-silt.svg',
  IconIso7010W062WarningKiteSurfing = 'iso7010/ISO-7010-W062-warning-kite-surfing.svg',
  IconIso7010W063WarningParasailing = 'iso7010/ISO-7010-W063-warning-parasailing.svg',
  IconIso7010W064WarningStrongWinds = 'iso7010/ISO-7010-W064-warning-strong-winds.svg',
  IconIso7010W065WarningHighSurfOrLargeBreakingWaves = 'iso7010/ISO-7010-W065-warning-high-surf-or-large-breaking-waves.svg',
  IconIso7010W066WarningSuddenDropToDeepWater = 'iso7010/ISO-7010-W066-warning-Sudden-drop-to-deep-water.svg',
  IconIso7010W067WarningAlligatorsCaymansAndCrocodiles = 'iso7010/ISO-7010-W067-warning-alligators-caymans-and-crocodiles.svg',
  IconSignFirstAid = 'iso7010/Sign-first-aid.svg',
}
